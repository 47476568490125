import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {Profession} from "../../Models/profession.model";
import {MatSort} from "@angular/material/sort";
import {MatPaginator} from "@angular/material/paginator";
import {MatDialog} from "@angular/material/dialog";
import {ToastrService} from "ngx-toastr";
import {ProfessionService} from "../../Services/profession.service";
import {PublicationModel} from "../../Models/publication.model";
import {AddprofessionComponent} from "../../Modals/addprofession/addprofession.component";
import {ConfirmationComponent} from "../../Modals/confirmation/confirmation.component";
import {PublicationService} from "../../Services/publication.service";
import {AddpublicationComponent} from "../../Modals/addpublication/addpublication.component";

@Component({
  selector: 'app-publication',
  templateUrl: './publication.component.html',
  styleUrls: ['./publication.component.scss']
})
export class PublicationComponent implements OnInit {
  tableDataSource = new MatTableDataSource();
  public displayedColumns = ['lien', 'datedebut', 'datefin', 'url','statutPublication', 'Actions'];
  publication = new PublicationModel();
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  constructor(public dialog: MatDialog, private toastr: ToastrService,private svcePublication: PublicationService) { }


  ngOnInit(): void {
    this.publication.typeAction = 'add';
    this.getAllPublication();
  }

  getAllPublication = () => {
    this.svcePublication.getAllPublication().subscribe(
      (res) => {
        console.log(res);
        if (res !== null && !res['status'] && !res['donnees'] !== null) {
          this.tableDataSource.data = res['donnees'];
          this.tableDataSource.sort = this.sort;
          this.tableDataSource.paginator = this.paginator;
        }
        else if (res !== null && res['status'] && !res['message'] !== null ){
          this.toastr.warning(res['message'], 'Message', {
            timeOut: 3000,
          });
          this.tableDataSource = new MatTableDataSource();
        }
        else{
          this.toastr.error('Oups! Erreur pendant de traitement veuillez contacter l\'administrateur', 'Message', {
            timeOut: 3000,
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  AddOrUpdate = (publication : PublicationModel): void => {
    const dialogRef = this.dialog.open(AddpublicationComponent, {
      width: '600px',
      disableClose: true,
      panelClass: 'myapp-no-padding-dialog',
      position: {top: '5%'},
      data: {
        publication
       //  message: 'Vous êtes sur le point de supprimer ' + this.publication.libelle + '. Êtes-vous sûr(e) ?'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getAllPublication();
      }
      console.log(result);
      this.publication = new PublicationModel();
    });
  }
  doFilter = (value: string) => {
    this.tableDataSource.filter = value.trim().toLocaleLowerCase();
  }
  update = (publication: PublicationModel) => {
    this.publication = publication;
    this.publication.typeAction = 'updated';
    this.AddOrUpdate(this.publication);
  }
  delete = (publication: PublicationModel) => {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '500px',
      disableClose: true,
      panelClass: 'myapp-no-padding-dialog',
      position: {top: '5%'},
      data: {
        message: 'Vous êtes sur le point de supprimer ' + publication.libelle + '. Êtes-vous sûr(e) ?'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.svcePublication.deletePublication(publication.publicationId).subscribe(
          (res) => {
            // console.log(res);
            if (res !== null && !res['status'] && !res['donnees'] !== null) {
              this.getAllPublication();
              this.toastr.success('Opération éffectuée avec succès', 'Message', {
                timeOut: 3000,
              });
            }
            else if (res !== null && res['status'] && !res['message'] !== null ){
              this.toastr.warning(res['message'], 'Message', {
                timeOut: 3000,
              });
            }
            else{
              this.toastr.error('Oups! Erreur pendant de traitement veuillez contacter l\'administrateur', 'Message', {
                timeOut: 3000,
              });
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
    });
  }



  redirectToChangeStatut = (statutPublication: number, publicationId: string, e: any) => {
    const libelle = statutPublication === 1 ? 'd\'activer' : 'de désactiver';

    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '500px',
      disableClose: true,
      panelClass: 'myapp-no-padding-dialog',
      position: {top: '5%'},
      data: {
        message: 'Vous êtes sur le point ' + libelle + '. Êtes-vous sûr(e) ?'
      }
    });
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result) {
        this.svcePublication.activerOrDesactiverPublication(statutPublication, publicationId).subscribe(
          (res) => {
            if (res !== null && !res[`status`]){
              this.toastr.success('Opération effctuée avec succès', 'Message', {
                timeOut: 3000,
              });
              this.getAllPublication();
            }
            else{
              this.toastr.warning(res[`message`], 'Message', {
                timeOut: 3000,
              });
            }
          }
        );
      } else {
        e.checked = statutPublication !== 1;
      }
    });
  }
  cleanFields = () => {
    this.publication.typeAction = 'add';
    // this.publication.createdby = localStorage.getItem('USER_CONNECTED_ID');
    this.publication.publicationId = '';
    this.publication.lien = '';
    this.publication.datedebut = '';
    this.publication.libelle = '';
    this.publication.datefin = '';
    this.publication.statutPublication = 0;
  }
}
