<div class="wrapper wrapper-full-page ">
  <div class="full-page register-page section-image" filter-color="black" style="min-height: calc(100vh);background-image: url('../../../assets/img/bg/jan-sendereks.jpg')">
    <div class="content">
    <div class="container">
      <div class="col-lg-4 col-md-6 ml-auto mr-auto">
        <form class="form" method="" action="#">
          <div class="card card-login">
            <div class="card-header ">
              <div class="card-header ">
                <h3 class="header text-center">Connexion</h3>
                <p class="card-description text-center"> Rassurez vous d'avoir les habilitations nécessaires avant toute tentative de connexion </p>
              </div>
            </div>
            <form class="form" [formGroup]="LoginForm" method="" action="#" >
            <div class="card-body ">
              <div class="input-group">
                <div class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="nc-icon nc-mobile"></i>
                      </span>
                </div>
                <input type="text" class="form-control" required name="username" formControlName="username" [dropSpecialCharacters]="true" [showMaskTyped]="true" mask="00 00 00 00 00" placeholder="Email...">
              </div>
              <div class="input-group">
                <div class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="nc-icon nc-key-25"></i>
                      </span>
                </div>
                <input type="password" placeholder="Mot de passe" required name="password" formControlName="password" class="form-control">
              </div>
              <br />
              <!--<div class="form-group">
                <div class="form-check">
                  <label class="form-check-label">
                    <input class="form-check-input" type="checkbox" value="" checked="">
                    <span class="form-check-sign"></span>
                    Subscribe to newsletter
                  </label>
                </div>
              </div>-->
            </div>
            </form>
            <div class="card-footer text-center">
              <!--<a href="javascript:;" class="btn btn-warning btn-round btn-block mb-3">Get Started</a>-->
              <button type="button" [disabled]="!LoginForm.valid" (click)="OnLogin()" class="btn btn-info btn-block mb-3">Me connecter</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
    <app-footer></app-footer>
  </div>
</div>
