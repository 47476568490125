import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {FuncService} from '../../Tools/glefunctions';

@Component({
  selector: 'app-deconnexion',
  templateUrl: './deconnexion.component.html',
  styleUrls: ['./deconnexion.component.scss']
})
export class DeconnexionComponent implements OnInit {

  // tslint:disable-next-line:max-line-length
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private funcs: FuncService, public dialogRef: MatDialogRef<DeconnexionComponent>, private toastr: ToastrService ) { }

  ngOnInit(): void {
  }
  // Fermerture du dialog
  close = (etat: boolean) => {
    this.dialogRef.close(etat);
  }
}
