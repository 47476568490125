import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import * as myGlobals from '../Tools/staticParams';
import { throwError, Observable } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import {Video} from '../Models/video.model';

@Injectable({
  providedIn: 'root'
})
export class VideoService {

  controller: string = 'coVideo.php';
  /*Headers*/
  headers = new HttpHeaders().set('Content-Type', 'application/json');
  constructor(private httpQuery: HttpClient) {
    /*  this.headers.append('Content-Type', 'multipart/form-data');
     this.headers.append('Accept', 'application/json'); */
    this.headers.append('Access-Control-Allow-Origin', '*');
  }
  /*Gestion des error HttpClient*/
  handleError = (error: HttpErrorResponse) => {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    // window.alert(errorMessage);
    return throwError(errorMessage);
  }
  // Add
  addVideo =  (video: Video): Observable<Video[]> => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('videoId', typeof (video.videoid) !== 'undefined' ? video.videoid : '');
    FORMDATA.append('commentaire', video.commentaire);
    FORMDATA.append('video', video.video);
    FORMDATA.append('videoFile', video.videoFile);
    FORMDATA.append('thumbnail', video.thumbailVideo);
    // tslint:disable-next-line:max-line-length
    FORMDATA.append('createdBy', typeof(video.createdby) === 'undefined' || video.createdby === '' ? myGlobals.USER_CONNECTED_ID : video.createdby);

    return this.httpQuery.post<any>(`${myGlobals.API_REST_URL_DEV}${this.controller}?Action=${video.typeAction === 'add' ? myGlobals.INSERT_ACTION : myGlobals.UPDATEBY_ACTION}`, FORMDATA)
      .pipe(retry(1), catchError(this.handleError));
  }
  // Read
  getAllVideo =  (): Observable<Video[]> => {
    // tslint:disable-next-line:max-line-length
    return this.httpQuery.get<Video[]>(`${myGlobals.API_REST_URL_DEV}${this.controller}?Action=${myGlobals.SELECTALL_ACTION}`, {headers: this.headers})
      .pipe(retry(1), catchError(this.handleError));
  }
  // Update
  updateVideo(id: number, data: Video): Observable<any> {
    const API_URL = `${myGlobals.API_REST_URL_DEV}${this.controller}`;
    return this.httpQuery.put(API_URL, data, { headers: this.headers }).pipe(
      retry(3), catchError(this.handleError)
    );
  }
  // Delete
  deleteVideo(video: Video): Observable<any> {
    const API_URL = `${myGlobals.API_REST_URL_DEV}${this.controller}?Action=${myGlobals.DELETEBY_ACTION }&videoId=${video.videoid}&status=${video.typeAction === 'delete' ? 0 : video.status}`;
    return this.httpQuery.delete(API_URL, { headers: this.headers }).pipe(
      retry(1), catchError(this.handleError)
    );
  }
}
