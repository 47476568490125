<div class="card">
  <div class="card-header">
    <div class="row">
      <div class="col-md-6">
        <h4 class="card-title"> Foire à questions</h4>
      </div>
      <div class="col-md-6">
        <div class="button_add" style="text-align: right">
          <button class="btn btn-action-orange waves-effect" type="button" (click)="AddOrUpdate()">
              <span class="btn-label">
                <i class="nc-icon nc-simple-add"></i>
              </span>
            Nouveau
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="card-body">
    <div class="table-responsive">
      <div class="row">
        <div *ngFor="let item of listeFAQ" class="col-sm-3 margin-bottom-10">
          <div class="card">
            <div class="card-header">
              <h4 style="font-weight: bolder">{{item.question}}</h4>
            </div>
            <!--<video controls="" src="https://codingyaar.com/wp-content/uploads/video-in-bootstrap-card.mp4" class="bs-card-video"></video>-->
            <div class="card-body">
              <p class="card-text">{{(item.reponse.length>170)? (item.reponse | slice:0:170)+'...':(item.reponse)}}</p>
            </div>
            <div class="card-footer">
              <div class="row">
                <div class="col-sm-5">
                  <button class="btn btn-action-red btn-round btn-icon" (click)="item.typeAction = 'delete'; delete(item)">
                    <i class="fa fa-trash"></i>
                  </button>
                  <button class="btn btn-action-blue btn-round btn-icon" (click)="update(item)" style="margin-left: 10px">
                    <i class="fa fa-edit"></i>
                  </button>
                </div>
                <div class="col-sm-7">
                  <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" [checked]="item.status === '1' ? true : false" (change)="item.typeAction = 'update'; changeState($event.target, item)" id="customSwitch_{{item.id}}">
                    <label class="custom-control-label" for="customSwitch_{{item.id}}"> Activer / Désactiver</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
