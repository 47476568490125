<div class="card">
  <div class="card-header">
    <div class="row">
      <div class="col-md-6">
        <h4 class="card-title"> Liste des actualités</h4>
      </div>
      <div class="col-md-6">
        <div class="button_add" style="text-align: right">
          <button class="btn btn-action-orange waves-effect" type="button" (click)="AddOrUpdate()">
              <span class="btn-label">
                <i class="nc-icon nc-simple-add"></i>
              </span>
            Nouveau
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="card-body">
    <div class="table-responsive">
      <div class="row">
        <div *ngFor="let item of listeActualites" class="col-sm-3 margin-bottom-10">
          <div class="card">
            <img src="{{this.filePath + 'actualites/' + item.image}}" class="card-img-top" alt="...">
            <!--<video controls="" src="https://codingyaar.com/wp-content/uploads/video-in-bootstrap-card.mp4" class="bs-card-video"></video>-->
            <div class="card-header">
              <h4 style="font-weight: bolder">{{(item.titre.length>80)? (item.titre | slice:0:80)+'...':(item.titre)}}</h4>
            </div>
            <div class="card-body contentText">
              <p class="card-text">{{(item.introduction.length>100)? (item.introduction | slice:0:100)+'...':(item.introduction)}}</p>
            </div>
            <div class="card-footer">
              <div class="row">
                <div class="col-sm-5 col-xs-6 col-md-6">
                  <button class="btn btn-action-red btn-round btn-icon" (click)="item.typeAction = 'delete'; delete(item)">
                    <i class="fa fa-trash"></i>
                  </button>
                  <button class="btn btn-action-blue btn-round btn-icon" (click)="update(item)" style="margin-left: 10px">
                    <i class="fa fa-edit"></i>
                  </button>
                </div>
                <div class="col-sm-7 col-xs-6 col-md-6">
                  <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" [checked]="item.status === '1' ? true : false" (change)="item.typeAction = 'update'; changeState($event.target, item)" id="customSwitch_{{item.id}}">
                    <label class="custom-control-label"  for="customSwitch_{{item.id}}"> Activer / Désactiver</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--<div class="col-sm-3">
          <div class="card">
            <img src="../../../assets/img/bg/jan-sendereks.jpg" class="card-img-top" alt="...">
            <div class="card-body text-center">
              <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
            </div>
            <div class="card-footer">
              <div class="row">
                <div class="col-sm-5">
                  <button class="btn btn-danger btn-round btn-icon">
                    <i class="fa fa-trash"></i>
                  </button>
                  <button class="btn btn-primary btn-round btn-icon" style="margin-left: 10px">
                    <i class="fa fa-edit"></i>
                  </button>
                </div>
                <div class="col-sm-7">
                  <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="customSwitch2">
                    <label class="custom-control-label" for="customSwitch2"> Activer / Désactiver</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="card">
            <img src="../../../assets/img/bg/jan-sendereks.jpg" class="card-img-top" alt="...">
            <div class="card-body text-center">
              <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
            </div>
            <div class="card-footer">
              <div class="row">
                <div class="col-sm-5">
                  <button class="btn btn-danger btn-round btn-icon">
                    <i class="fa fa-trash"></i>
                  </button>
                  <button class="btn btn-primary btn-round btn-icon" style="margin-left: 10px">
                    <i class="fa fa-edit"></i>
                  </button>
                </div>
                <div class="col-sm-7">
                  <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="customSwitch3">
                    <label class="custom-control-label" for="customSwitch3"> Activer / Désactiver</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="card">
            <img src="../../../assets/img/bg/jan-sendereks.jpg" class="card-img-top" alt="...">
            <div class="card-body text-center">
              <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
            </div>
            <div class="card-footer">
              <div class="row">
                <div class="col-sm-5">
                  <button class="btn btn-danger btn-round btn-icon">
                    <i class="fa fa-trash"></i>
                  </button>
                  <button class="btn btn-primary btn-round btn-icon" style="margin-left: 10px">
                    <i class="fa fa-edit"></i>
                  </button>
                </div>
                <div class="col-sm-7">
                  <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="customSwitch4">
                    <label class="custom-control-label" for="customSwitch4"> Activer / Désactiver</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>-->
      </div>
    </div>
  </div>
</div>
