import { Injectable } from '@angular/core';

declare var $: any;
@Injectable({
  providedIn: 'root'
})
export class FuncService {
  constructor() { }
  // Prise en compte de l'image
  initDropify = (img: any) => {
    let drEvent = $('.dropify').dropify(
      {
        // defaultFile: img,
        showRemove: false,
        height: 150,
        width: 150,
        allowedFileExtensions: 'jpeg jpg png',
        showMessage: false,
        messages: {
          default: 'Cliquez pour remplacer',
          replace: 'Cliquez pour remplacer',
          remove:  'Supprimer',
          error:   'Ooops, un probleme.'
        },
        tpl: {
          wrap:            '<div class="dropify-wrapper"></div>',
          loader:          '<div class="dropify-loader"></div>',
          message:         '<div class="dropify-message"><span class="file-icon" /> <p>{{ default }}</p></div>',
          preview:         '<div class="dropify-preview"><span class="dropify-render"></span><div class="dropify-infos"><div class="dropify-infos-inner"><p class="dropify-infos-message">{{ replace }}</p></div></div></div>',
          filename:        '<p class="dropify-filename"></p>',
          clearButton:     '<button type="button" class="dropify-clear">{{ remove }}</button>',
          errorLine:       '<p class="dropify-error">{{ error }}</p>',
          errorsContainer: '<div class="dropify-errors-container"><ul></ul></div>'
        }
      });
    drEvent = drEvent.data('dropify');
    drEvent.resetPreview();
    drEvent.clearElement();
    drEvent.settings.defaultFile = img;
    drEvent.destroy();
    drEvent.init();
  }

  uploadefile = () => {
    const inputs = document.querySelectorAll( '.inputfile' );
    // tslint:disable-next-line:only-arrow-functions
    Array.prototype.forEach.call( inputs, function( input )
    {
      const label	 = input.nextElementSibling;
      const labelVal = label.innerHTML;

      input.addEventListener( 'change', function( e )
      {
        let fileName = '';
        if ( this.files && this.files.length > 1 ) {
          fileName = ( this.getAttribute( 'data-multiple-caption' ) || '' ).replace( '{count}', this.files.length );
        }
        else {
          fileName = e.target.value.split( '\\' ).pop();
        }

        if ( fileName ) {
          label.querySelector( 'span' ).innerHTML = fileName;
        }
        else {
          label.innerHTML = labelVal;
        }
      });

      // Firefox bug fix
      // tslint:disable-next-line:only-arrow-functions
      input.addEventListener( 'focus', function(){ input.classList.add( 'has-focus' ); });
      // tslint:disable-next-line:only-arrow-functions
      input.addEventListener( 'blur', function(){ input.classList.remove( 'has-focus' ); });
    });
  }


}


