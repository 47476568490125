import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import * as myGlobals from '../Tools/staticParams';
import { throwError, Observable } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import {Profession} from '../Models/profession.model';

@Injectable({
  providedIn: 'root'
})
export class ProfessionService {
  controller: string = 'coProfession.php';
  /*Headers*/
  headers = new HttpHeaders().set('Content-Type', 'application/json');
  constructor(private httpQuery: HttpClient) {
    /*  this.headers.append('Content-Type', 'multipart/form-data');
     this.headers.append('Accept', 'application/json'); */
    this.headers.append('Access-Control-Allow-Origin', '*');
  }
  /*Gestion des error HttpClient*/
  handleError = (error: HttpErrorResponse) => {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    // window.alert(errorMessage);
    return throwError(errorMessage);
  }
  // Add
  addProfession =  (profession: Profession): Observable<Profession[]> => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('professionid', typeof (profession.professionid) !== 'undefined' ? profession.professionid : '');
    FORMDATA.append('categorieid', profession.categorieid);
    FORMDATA.append('libelle', profession.libelle);
    FORMDATA.append('code', profession.code);
    FORMDATA.append('commentaire', profession.commentaire);
    // tslint:disable-next-line:max-line-length
    FORMDATA.append('createdby', typeof(profession.createdby) === 'undefined' || profession.createdby === '' ? myGlobals.USER_CONNECTED_ID : profession.createdby);

    return this.httpQuery.post<any>(`${myGlobals.API_REST_URL_DEV}${this.controller}?Action=${profession.typeAction === 'add' ? myGlobals.INSERT_ACTION : myGlobals.UPDATEBY_ACTION}`, FORMDATA)
      .pipe(retry(1), catchError(this.handleError));
  }
  // Read
  getAllProfession =  (): Observable<Profession[]> => {
    // tslint:disable-next-line:max-line-length
    return this.httpQuery.get<Profession[]>(`${myGlobals.API_REST_URL_DEV}${this.controller}?Action=${myGlobals.SELECTALL_ACTION}`, {headers: this.headers})
      .pipe(retry(1), catchError(this.handleError));
  }
  // Update
  updateProfession(id: number, data: Profession): Observable<any> {
    const API_URL = `${myGlobals.API_REST_URL_DEV}${this.controller}`;
    return this.httpQuery.put(API_URL, data, { headers: this.headers }).pipe(
      retry(3), catchError(this.handleError)
    );
  }
  // Delete
  deleteProfession(profession: Profession): Observable<any> {
    const API_URL = `${myGlobals.API_REST_URL_DEV}${this.controller}?Action=${myGlobals.DELETEBY_ACTION }&professionid=${profession.professionid}`;
    return this.httpQuery.delete(API_URL, { headers: this.headers }).pipe(
      retry(1), catchError(this.handleError)
    );
  }
}
