import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {ToastrService} from 'ngx-toastr';
import {MatDialog} from '@angular/material/dialog';
import {AbonneService} from '../../Services/abonne.service';
import {Utilisateur} from '../../Models/utilisateur.model';
import {ConfirmationComponent} from '../../Modals/confirmation/confirmation.component';
import {AdduserComponent} from '../../Modals/adduser/adduser.component';
import {AccessService} from '../../Services/access.service';
import {TypeutilisateurService} from "../../Services/typeutilisateur.service";
import {Typeutilisateur} from "../../Models/typeutilisateur.model";
import {AddaccesComponent} from "../../Modals/addacces/addacces.component";
@Component({
  selector: 'app-utilisateur',
  templateUrl: './utilisateur.component.html',
  styleUrls: ['./utilisateur.component.scss']
})
export class UtilisateurComponent implements OnInit {
  clutilisateur = new Utilisateur();
  listeType: Typeutilisateur[] = [];
  tableDataSource = new MatTableDataSource();
  public displayedColumns = ['nom', 'prenoms', 'type', 'code', 'tel', 'Actions'];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  constructor(public dialog: MatDialog, private toastr: ToastrService, private svceAbone: AccessService, private svceUser: AbonneService, private svceType: TypeutilisateurService) { }

  ngOnInit(): void {
    this.getAllAbonnes();
  }
  // Récupération des produits
  getAllAbonnes = () => {
    this.svceAbone.getAllAccess().subscribe(
      (res) => {
        console.log(res);
        if (res !== null && !res['status'] && !res['donnees'] !== null) {
          this.tableDataSource.data = res['donnees'];
          this.tableDataSource.sort = this.sort;
          this.tableDataSource.paginator = this.paginator;
        }
        else if (res !== null && res['status'] && !res['message'] !== null ){
          this.toastr.warning(res['message'], 'Message', {
            timeOut: 3000,
          });
          this.tableDataSource = new MatTableDataSource();
        }
        else{
          this.toastr.error('Oups! Erreur pendant de traitement veuillez contacter l\'administrateur', 'Message', {
            timeOut: 3000,
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  // récupérer les Types users
  getAllTypes = () => {
    this.svceType.getAllTypeutilisateur().subscribe(
      (res) => {
        console.log(res);
        if (res !== null && !res['status'] && !res['donnees'] !== null) {
          this.listeType = res['donnees'];
        }
        else if (res !== null && res['status'] && !res['message'] !== null ){
          this.toastr.warning(res['message'], 'Message', {
            timeOut: 3000,
          });
          this.listeType = [];
        }
        else{
          this.toastr.error('Oups! Erreur pendant de traitement veuillez contacter l\'administrateur', 'Message', {
            timeOut: 3000,
          });
          this.listeType = [];
        }
      },
      (error) => {
        console.log(error);
        this.listeType = [];
      }
    );
  }
  AddOrUpdate = (): void => {
    const dialogRef = this.dialog.open(AdduserComponent, {
      width: '600px',
      disableClose: true,
      panelClass: 'myapp-no-padding-dialog',
      position: {top: '5%'},
      data: {
        'data': this.clutilisateur,
        'message': 'Vous êtes sur le point de supprimer ' + this.clutilisateur.nom + ' ' + this.clutilisateur.prenoms + '. Êtes-vous sûr(e) ?'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && this.clutilisateur.typeAction !== 'delete') {
        this.getAllAbonnes();
      } else {
        if (result && this.clutilisateur.typeAction === 'delete') {
          this.svceAbone.deleteAccess(this.clutilisateur.utilisateurid).subscribe(
            (res) => {
              console.log(res);
              if (res !== null && !res['status']) {
                this.toastr.success(res['message'], 'Message'
                  , {timeOut: 3000, progressBar: true,});
                this.getAllAbonnes();
              } else {
                this.toastr.error(res['message'], 'Message'
                  , {timeOut: 3000, progressBar: true,});
              }
            },
            (error) => {
              console.log(error);
            }
          );
        }
      }
      console.log(result);
      this.clutilisateur = new Utilisateur();
      // this.animal = result;
    });
  }
  doFilter = (value: string) => {
    this.tableDataSource.filter = value.trim().toLocaleLowerCase();
  }
  update = (utilisateur: Utilisateur) => {
    this.clutilisateur = utilisateur;
    this.clutilisateur.typeAction = 'update';
    this.AddOrUpdate();
  }
  delete = (clutilisateur: Utilisateur) => {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '500px',
      disableClose: true,
      panelClass: 'myapp-no-padding-dialog',
      position: {top: '5%'},
      data: {
        message: 'Vous êtes sur le point de supprimer ' + clutilisateur.nom + ' ' + clutilisateur.prenoms + '. Êtes-vous sûr(e) ?'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.svceUser.deleteUtilisateur(clutilisateur.utilisateurid).subscribe(
          (res) => {
            // console.log(res);
            if (res !== null && !res['status'] && !res['donnees'] !== null) {
              this.getAllAbonnes();
              this.toastr.success('Opération éffectuée avec succès', 'Message', {
                timeOut: 3000,
              });
            }
            else if (res !== null && res['status'] && !res['message'] !== null ){
              this.toastr.warning(res['message'], 'Message', {
                timeOut: 3000,
              });
            }
            else{
              this.toastr.error('Oups! Erreur pendant de traitement veuillez contacter l\'administrateur', 'Message', {
                timeOut: 3000,
              });
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
    });
  }

  addAccess = (clutilisateur: Utilisateur) => {
    clutilisateur.typeAction = 'update';
    const dialogRef = this.dialog.open(AddaccesComponent, {
      width: '500px',
      disableClose: true,
      panelClass: 'myapp-no-padding-dialog',
      position: {top: '5%'},
      data: {
        data: clutilisateur,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getAllAbonnes();
      }
    });
  }
}
