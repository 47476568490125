import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import * as myGlobals from '../Tools/staticParams';
import { throwError, Observable } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import {Dashboard} from "../Models/dashboard.model";

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  controller: string = 'coDashboard.php';
  /*Headers*/
  headers = new HttpHeaders().set('Content-Type', 'application/json');
  constructor(private httpQuery: HttpClient) {
    /*  this.headers.append('Content-Type', 'multipart/form-data');
     this.headers.append('Accept', 'application/json'); */
    this.headers.append('Access-Control-Allow-Origin', '*');
  }
  /*Gestion des error HttpClient*/
  handleError = (error: HttpErrorResponse) => {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    // window.alert(errorMessage);
    return throwError(errorMessage);
  }
  // Récupéré les compteurs sur le dashboard
  // Read
  getSelectAllInfos = (dashbord: Dashboard): Observable<any[]> => {
    // tslint:disable-next-line:max-line-length
    return this.httpQuery.get<any[]>(`${myGlobals.API_REST_URL_DEV}${this.controller}?Action=${dashbord.typeAction}`, {headers: this.headers})
      .pipe(retry(1), catchError(this.handleError));
  }
}
