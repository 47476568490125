import { BrowserModule } from '@angular/platform-browser';
import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import {HttpClient, HttpClientModule, HttpClientJsonpModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {IConfig, NgxMaskModule} from 'ngx-mask';
import { ToastrModule } from 'ngx-toastr';
import { PerfectScrollbarModule, PerfectScrollbarConfigInterface,
  PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { CookieService } from 'ngx-cookie-service';

// Material import module
import {MatDialogModule} from '@angular/material/dialog';
import { MatTableModule  } from '@angular/material/table';
import { MatSortModule  } from '@angular/material/sort';
import { MatPaginatorModule  } from '@angular/material/paginator';
// End Import

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AbonneComponent } from './Views/abonne/abonne.component';
import { VideoComponent } from './Views/video/video.component';
import { DashboardComponent } from './Views/dashboard/dashboard.component';
import { ActualiteComponent } from './Views/actualite/actualite.component';
import { AgenceComponent } from './Views/agence/agence.component';
import { UtilisateurComponent } from './Views/utilisateur/utilisateur.component';
import { ProfileComponent } from './Views/profile/profile.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './Auth/login/login.component';
import { ForgotComponent } from './Auth/forgot/forgot.component';
import { NotfoundComponent } from './404/notfound/notfound.component';
import { MainComponent } from './Layout/main/main.component';
import { HeaderComponent } from './Layout/header/header.component';
import { NavComponent } from './Layout/nav/nav.component';
import { SidebarComponent } from './Layout/sidebar/sidebar.component';
import { FooterComponent } from './Layout/footer/footer.component';
import { DeconnexionComponent } from './Modals/deconnexion/deconnexion.component';
import { ConfirmationComponent } from './Modals/confirmation/confirmation.component';
import { AdduserComponent } from './Modals/adduser/adduser.component';
import { FoireaqComponent } from './Views/foireaq/foireaq.component';
import { AddabonneComponent } from './Modals/addabonne/addabonne.component';
import { AddagenceComponent } from './Modals/addagence/addagence.component';
import { AddfaqComponent } from './Modals/addfaq/addfaq.component';
import { AddactualiteComponent } from './Modals/addactualite/addactualite.component';
import { AddvideoComponent } from './Modals/addvideo/addvideo.component';
import { DetailsabonnesComponent } from './Modals/detailsabonnes/detailsabonnes.component';
import { ProfessionComponent } from './Views/profession/profession.component';
import { AddprofessionComponent } from './Modals/addprofession/addprofession.component';
import { AddcategoriemetierComponent } from './Modals/addcategoriemetier/addcategoriemetier.component';
import { CategoriemetierComponent } from './Views/categoriemetier/categoriemetier.component';
import { AddphotoComponent } from './Modals/addphoto/addphoto.component';
import { PhotoComponent } from './Views/photo/photo.component';
import { AddaccesComponent } from './Modals/addacces/addacces.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {InterceptorService} from './Layout/loader/interceptor.service';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import { MasterdashboardComponent } from './Views/masterdashboard/masterdashboard.component';
import { PublicationComponent } from './Views/publication/publication.component';
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import { AddpublicationComponent } from './Modals/addpublication/addpublication.component';
import {MatInputModule} from "@angular/material/input";
import {MatDatepickerModule} from "@angular/material/datepicker";

// Gestion du mask
const maskConfigFunction: () => Partial<IConfig> = () => {
  return {
    validation: false,
  };
};
// Configuration du module du perfect scrollbar
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};
// Insertion des module du material design
const MaterialModule = [
  MatDialogModule,
  MatTableModule,
  MatSortModule,
  MatPaginatorModule,
  HttpClientModule,
  FormsModule,
  ReactiveFormsModule,
  MatProgressSpinnerModule,
];

@NgModule({
  declarations: [
    AppComponent,
    AbonneComponent,
    VideoComponent,
    DashboardComponent,
    ActualiteComponent,
    AgenceComponent,
    UtilisateurComponent,
    ProfileComponent,
    LoginComponent,
    ForgotComponent,
    NotfoundComponent,
    MainComponent,
    HeaderComponent,
    NavComponent,
    SidebarComponent,
    FooterComponent,
    DeconnexionComponent,
    ConfirmationComponent,
    AdduserComponent,
    FoireaqComponent,
    AddabonneComponent,
    AddagenceComponent,
    AddfaqComponent,
    AddactualiteComponent,
    AddvideoComponent,
    DetailsabonnesComponent,
    ProfessionComponent,
    AddprofessionComponent,
    AddcategoriemetierComponent,
    CategoriemetierComponent,
    AddphotoComponent,
    PhotoComponent,
    AddaccesComponent,
    MasterdashboardComponent,
    PublicationComponent,
    AddpublicationComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgxChartsModule,
    PerfectScrollbarModule,
    NgxMaskModule.forRoot(maskConfigFunction),
    ToastrModule.forRoot(),
    MaterialModule,
    MatSlideToggleModule,
    MatInputModule,
    // MatDatepickerModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [
    DatePipe,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    },
    CookieService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
