import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {FuncService} from '../../Tools/glefunctions';
import * as myGlobals from '../../Tools/staticParams';
import {Video} from '../../Models/video.model';
import {VideoService} from '../../Services/video.service';

@Component({
  selector: 'app-addvideo',
  templateUrl: './addvideo.component.html',
  styleUrls: ['./addvideo.component.scss']
})
export class AddvideoComponent implements OnInit {
  fileName: string;
  filePath: string = myGlobals.API_REST_URL_FILE;
  @ViewChild('videoFile') InputFile: ElementRef;
  ThumbailVideo: string;
  constructor(@Inject(MAT_DIALOG_DATA) public data: Video, private funcs: FuncService, public dialogRef: MatDialogRef<AddvideoComponent>, private toastr: ToastrService, private svceVideo: VideoService) { }


  ngOnInit(): void {
    /*this.fileName = typeof(this.data['data'].video) !== 'undefined' && this.data['data'].video !== '' ?
      this.filePath + 'users/' + this.data['data'].video : this.filePath + 'default_user.png';
    this.funcs.initDropify(this.fileName);*/

    this.funcs.uploadefile();

  }
// Fermerture du dialog
  close = (etat: boolean) => {
    this.dialogRef.close(etat);
  }
  // Récupere la miniature de lka video
  getThumbail = (files: File[]) => {
    const file = files[0];
    const fileReader = new FileReader();
    if (file.type.match('image')) {
      fileReader.onload = () => {
        const img = document.createElement('img');
        img.src = String(fileReader.result);
        document.getElementsByTagName('div')[0].appendChild(img);
      };
      fileReader.readAsDataURL(file);
    } else {
      fileReader.onload = () => {
        const blob = new Blob([fileReader.result], {type: file.type});
        const url = URL.createObjectURL(blob);
        const video = document.createElement('video');
        const timeupdate = () => {
          if (snapImage()) {
            video.removeEventListener('timeupdate', timeupdate);
            video.pause();
          }
        };
        video.addEventListener('loadeddata', () => {
          if (snapImage()) {
            video.removeEventListener('timeupdate', timeupdate);
          }
        });
        const snapImage = () => {
          const canvas = document.createElement('canvas');
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
          const image = canvas.toDataURL();
          const success = image.length > 100000;
          if (success) {
            const img = document.createElement('img');
            img.src = image;
            // console.log(image);
            this.ThumbailVideo =  image ;
            // document.getElementById('textImage').appendChild(img);
            URL.revokeObjectURL(url);
          }
          return success;
        };
        video.addEventListener('timeupdate', timeupdate);
        video.preload = 'metadata';
        video.src = url;
        // video.play();
      };
      fileReader.readAsArrayBuffer(file);
    }
  }
  // Ajout d'une vidéo
  insertVideo = () => {
    const file = this.InputFile.nativeElement;
    this.data['data'].videoFile = file.files && file.files[0] ?  file.files[0] : '';
    this.data['data'].thumbailVideo =  this.ThumbailVideo;
    // this.getThumbail(file);
     this.svceVideo.addVideo(this.data['data']).subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.toastr.success('Opération éffectuée avec succès', 'Message', {
            timeOut: 3000,
          });
          this.close(true);
        }else {
          this.toastr.warning(res['message'], 'Message', {
            timeOut: 3000,
          });
        }
      },
      (error) => {
        console.log(error);
        this.toastr.error('Oups!!! erreur lors de l\'opération', 'Message', {
          timeOut: 3000,
        });
      }
    );
  }
}
