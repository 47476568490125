import { Component, OnInit } from '@angular/core';
import * as myGlobals from '../../Tools/staticParams';
import {LoaderService} from '../loader/loader.service';
import {CookieService} from 'ngx-cookie-service';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  private myInfo = {};
  full: string = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '');
  constructor(public loaderService: LoaderService, private cookieSce: CookieService, private router: Router, private dialog: MatDialog) { }

  ngOnInit(): void {
    const  VAR_USER_CONNECTED_ID = myGlobals.USER_CONNECTED_ID ;
    if (VAR_USER_CONNECTED_ID === null || typeof (VAR_USER_CONNECTED_ID) === 'undefined')
    {
      window.localStorage.removeItem('RSTI_USER_CONNECTED_INFO');
      localStorage.removeItem('RSTI_USER_CONNECTED_INFO');
      window.localStorage.removeItem('RSTI_USER_CONNECTED_ID');
      localStorage.removeItem('RSTI_USER_CONNECTED_ID');
      window.location.href = this.full;
    }
  }

}
