<div *ngIf="this.loaderService.isLoading | async" class="loader-container"><mat-spinner style="top: 50%; left:50%"></mat-spinner> </div>
<app-sidebar></app-sidebar>
<div class="main-panel">
  <app-nav></app-nav>
  <div class="content">
    <div class="row">
      <div class="col-md-12">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</div>
