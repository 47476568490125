import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {Categoriemetier} from '../../Models/categoriemetier.model';
import {ToastrService} from 'ngx-toastr';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmationComponent} from '../../Modals/confirmation/confirmation.component';
import {CategoriemetierService} from '../../Services/categoriemetier.service';
import {AddcategoriemetierComponent} from '../../Modals/addcategoriemetier/addcategoriemetier.component';

@Component({
  selector: 'app-categoriemetier',
  templateUrl: './categoriemetier.component.html',
  styleUrls: ['./categoriemetier.component.scss']
})
export class CategoriemetierComponent implements OnInit {
  tableDataSource = new MatTableDataSource();
  public displayedColumns = ['libelle', 'code', 'tauxbase', 'tauxcomp', 'revenuemin', 'cotisationmin', 'Actions'];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  clCategorie = new Categoriemetier();
  constructor(public dialog: MatDialog, private toastr: ToastrService, private svceCat: CategoriemetierService) { }

  ngOnInit(): void {
    this.getAllCategoriemetiers();
  }
  // Récupération des Categoriemetiers
  getAllCategoriemetiers = () => {
    this.svceCat.getAllCategoriemetier().subscribe(
      (res) => {
        console.log(res);
        if (res !== null && !res['status'] && !res['donnees'] !== null) {
          this.tableDataSource.data = res['donnees'];
          this.tableDataSource.sort = this.sort;
          this.tableDataSource.paginator = this.paginator;
        }
        else if (res !== null && res['status'] && !res['message'] !== null ){
          this.toastr.warning(res['message'], 'Message', {
            timeOut: 3000,
          });
          this.tableDataSource = new MatTableDataSource();
        }
        else{
          this.toastr.error('Oups! Erreur pendant de traitement veuillez contacter l\'administrateur', 'Message', {
            timeOut: 3000,
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  AddOrUpdate = (): void => {
    const dialogRef = this.dialog.open(AddcategoriemetierComponent, {
      width: '600px',
      disableClose: true,
      panelClass: 'myapp-no-padding-dialog',
      position: {top: '5%'},
      data: {
        data: this.clCategorie,
        message: 'Vous êtes sur le point de supprimer ' + this.clCategorie.libelle + '. Êtes-vous sûr(e) ?'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      /*if (result) {
        this.getAllCategoriemetiers();
      }*/
      this.getAllCategoriemetiers();
      this.clCategorie = new Categoriemetier();
    });
  }
  doFilter = (value: string) => {
    this.tableDataSource.filter = value.trim().toLocaleLowerCase();
  }
  update = (clCategorie: Categoriemetier) => {
    this.clCategorie = clCategorie;
    this.clCategorie.typeAction = 'updated';
    this.AddOrUpdate();
  }
  delete = (categoriemetier: Categoriemetier) => {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '500px',
      disableClose: true,
      panelClass: 'myapp-no-padding-dialog',
      position: {top: '5%'},
      data: {
        message: 'Vous êtes sur le point de supprimer ' + categoriemetier.libelle + '. Êtes-vous sûr(e) ?'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.svceCat.deleteCategoriemetier(categoriemetier).subscribe(
          (res) => {
            // console.log(res);
            if (res !== null && !res['status'] && !res['donnees'] !== null) {
              this.getAllCategoriemetiers();
              this.toastr.success('Opération éffectuée avec succès', 'Message', {
                timeOut: 3000,
              });
            }
            else if (res !== null && res['status'] && !res['message'] !== null ){
              this.toastr.warning(res['message'], 'Message', {
                timeOut: 3000,
              });
            }
            else{
              this.toastr.error('Oups! Erreur pendant de traitement veuillez contacter l\'administrateur', 'Message', {
                timeOut: 3000,
              });
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
    });
  }
}
