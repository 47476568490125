import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {FuncService} from '../../Tools/glefunctions';
import * as myGlobals from '../../Tools/staticParams';
import {Photo} from '../../Models/photo.model';
import {PhotoService} from '../../Services/photo.service';

@Component({
  selector: 'app-addphoto',
  templateUrl: './addphoto.component.html',
  styleUrls: ['./addphoto.component.scss']
})
export class AddphotoComponent implements OnInit {
  fileName: string;
  filePath: string = myGlobals.API_REST_URL_FILE;
  @ViewChild('imageFile') InputFile: ElementRef;
  constructor(@Inject(MAT_DIALOG_DATA) public data: Photo, private funcs: FuncService, public dialogRef: MatDialogRef<AddphotoComponent>, private toastr: ToastrService, private svcePhoto: PhotoService) { }

  ngOnInit(): void {
    this.fileName = typeof(this.data['data'].image) !== 'undefined' && this.data['data'].image !== '' ?
      this.filePath + 'images/' + this.data['data'].image : this.filePath + 'default_user.png';
    this.funcs.initDropify(this.fileName);
  }
  // Fermerture du dialog
  close = (etat: boolean) => {
    this.dialogRef.close(etat);
  }
  // Ajout d'une vidéo
  insertPhoto = () => {
    const file = this.InputFile.nativeElement;
    this.data['data'].imageFile = file.files && file.files[0] ?  file.files[0] : '';
    this.svcePhoto.addPhoto(this.data['data']).subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.toastr.success('Opération éffectuée avec succès', 'Message', {
            timeOut: 3000,
          });
          this.close(true);
        }else {
          this.toastr.warning(res['message'], 'Message', {
            timeOut: 3000,
          });
        }
      },
      (error) => {
        console.log(error);
        this.toastr.error('Oups!!! erreur lors de l\'opération', 'Message', {
          timeOut: 3000,
        });
      }
    );
  }
}
