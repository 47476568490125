import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import * as myGlobals from '../Tools/staticParams';
import { throwError, Observable } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import {Photo} from '../Models/photo.model';

@Injectable({
  providedIn: 'root'
})
export class PhotoService {
  controller = 'coImage.php';
  /*Headers*/
  headers = new HttpHeaders().set('Content-Type', 'application/json');
  constructor(private httpQuery: HttpClient) {
    /*  this.headers.append('Content-Type', 'multipart/form-data');
     this.headers.append('Accept', 'application/json'); */
    this.headers.append('Access-Control-Allow-Origin', '*');
  }
  /*Gestion des error HttpClient*/
  handleError = (error: HttpErrorResponse) => {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    // window.alert(errorMessage);
    return throwError(errorMessage);
  }
  // Add
  addPhoto =  (photo: Photo): Observable<Photo[]> => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('photoId', typeof (photo.imageid) !== 'undefined' ? photo.imageid : '');
    FORMDATA.append('commentaire', photo.commentaire);
    FORMDATA.append('photo', photo.image);
    FORMDATA.append('imageFile', photo.imageFile);
    // tslint:disable-next-line:max-line-length
    FORMDATA.append('createdby', typeof(photo.createdby) === 'undefined' || photo.createdby === '' ? myGlobals.USER_CONNECTED_ID : photo.createdby);

    return this.httpQuery.post<any>(`${myGlobals.API_REST_URL_DEV}${this.controller}?Action=${photo.typeAction === 'add' ? myGlobals.INSERT_ACTION : myGlobals.UPDATEBY_ACTION}`, FORMDATA)
      .pipe(retry(1), catchError(this.handleError));
  }
  // Read
  getAllPhoto =  (): Observable<Photo[]> => {
    // tslint:disable-next-line:max-line-length
    return this.httpQuery.get<Photo[]>(`${myGlobals.API_REST_URL_DEV}${this.controller}?Action=${myGlobals.SELECTALL_ACTION}`, {headers: this.headers})
      .pipe(retry(1), catchError(this.handleError));
  }
  // Update
  updatePhoto(id: number, data: Photo): Observable<any> {
    const API_URL = `${myGlobals.API_REST_URL_DEV}${this.controller}`;
    return this.httpQuery.put(API_URL, data, { headers: this.headers }).pipe(
      retry(3), catchError(this.handleError)
    );
  }
  // Delete
  deletePhoto(photo: Photo): Observable<any> {
    const API_URL = `${myGlobals.API_REST_URL_DEV}${this.controller}?Action=${myGlobals.DELETEBY_ACTION }&imageid=${photo.imageid}&status=${photo.typeAction === 'delete' ? 0 : photo.status}`;
    return this.httpQuery.delete(API_URL, { headers: this.headers }).pipe(
      retry(1), catchError(this.handleError)
    );
  }
}
