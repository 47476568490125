<div class="card">
  <div class="card-header">
    <h4 class="card-title"> Liste des utilisateurs</h4>
  </div>
  <div class="card-body">
    <div class="table-responsive">
      <div class="row">
        <div class="col-md-6">
          <div class="row" style="width: 100%; float: left">
            <div class="col-md-12">
              <div id="example23_filter" class="dataTables_filter form-horizontal row">
                <label class="col-md-1 col-form-label" style="float: left">Recherche :</label>
                <div class="col-md-9" style="float: left">
                  <div class="form-group">
                    <input type="search" (keyup)="doFilter($event.target.value)" class="form-control" placeholder="Rechercher..." aria-controls="Rechercher">
                  </div>
                </div>
                <!--<label>Recherche:<input type="search" (keyup)="doFilter($event.target.value)" class="form-control" placeholder="" aria-controls="example23"></label>-->
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6" style="text-align: right">
          <div class="button_add">
            <button class="btn btn-action-orange waves-effect" (click)="AddOrUpdate()" type="button">
             <span class="btn-label">
                <i class="nc-icon nc-simple-add"></i>
              </span>
              Nouveau
            </button>
          </div>
        </div>
      </div>
      <table mat-table class="table table-bordered table-striped dataTable table table-responsive-md" cellspacing="0" width="100%" role="grid" aria-describedby="example23_info" style="width: 100%;" [dataSource]="tableDataSource" matSort>

        <tr role="row" class="even">
          <ng-container matColumnDef="nom" role="row" class="even">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Nom </th>
            <td mat-cell *matCellDef="let element"> {{element.nom}} </td>
          </ng-container>
        </tr>
        <tr role="row" class="even">
          <ng-container matColumnDef="prenoms" role="row" class="even">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Prénom(s) </th>
            <td mat-cell *matCellDef="let element"> {{element.prenoms}} </td>
          </ng-container>
        </tr>
        <tr role="row" class="even">
          <ng-container matColumnDef="type" role="row" class="even">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Type </th>
            <td mat-cell *matCellDef="let element"> {{element.libelle}} </td>
          </ng-container>
        </tr>
        <tr role="row" class="even">
          <ng-container matColumnDef="code" role="row" class="even">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Code </th>
            <td mat-cell *matCellDef="let element"> {{element.code}} </td>
          </ng-container>
        </tr>
        <tr role="row" class="even">
          <ng-container matColumnDef="tel" role="row" class="even">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Contact </th>
            <td mat-cell *matCellDef="let element"> {{element.tel | mask: '00 00 00 00 00'}} </td>
          </ng-container>
        </tr>
        <tr role="row" class="even">
          <ng-container matColumnDef="Actions">
            <th mat-header-cell *matHeaderCellDef style="width: 2%;text-align: center"> Actions </th>
            <td mat-cell *matCellDef=" let element" style="width:2%;text-align: center">
              <div class="dropdown">
                <button class="dropdown-toggle btn btn-action-green  btn-block" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i class="nc-icon nc-align-center"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                  <!--<div class="dropdown-header">Dropdown header</div>-->
                  <a class="dropdown-item" href="javascript:void(0);" (click)="addAccess(element)"><i class="fa fa-key"></i> Mes Accès</a>
                  <a class="dropdown-item" href="javascript:void(0);" (click)="update(element)"><i class="fa fa-edit"></i> Modifier</a>
                  <a class="dropdown-item" href="javascript:void(0);" (click)="delete(element)"><i class="fa fa-stack-exchange"></i> Supprimer</a>
                </div>
              </div>
            </td>
          </ng-container>
        </tr>
        <tr role="row" class="even">
          <ng-container matColumnDef="Loading">
            <th class="w-75" mat-footer-cell *matFooterCellDef colspan="9" style="text-align: center;">
              Chargement en cour....
            </th>
          </ng-container>
        </tr>
        <tr role="row" class="even">
          <ng-container matColumnDef="NoData">
            <th class="w-75" mat-footer-cell *matFooterCellDef colspan="9" style="text-align: center;">
              Aucunes données trouvées....
            </th>
          </ng-container>
        </tr>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <tr mat-footer-row *matFooterRowDef="['Loading']" [ngClass]="{'hide': tableDataSource != null }"></tr>
        <tr mat-footer-row *matFooterRowDef="['NoData']" [ngClass]="{'hide': !(tableDataSource != null && tableDataSource.data.length==0)}"></tr>
      </table>
      <mat-paginator [pageSizeOptions]="[10, 20, 40]" [pageSize]="10" showFirstLastButtons></mat-paginator>
    </div>
  </div>
</div>
