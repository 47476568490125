import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {ToastrService} from 'ngx-toastr';
import {MatDialog} from '@angular/material/dialog';
import {Profession} from '../../Models/profession.model';
import {ProfessionService} from '../../Services/profession.service';
import {Categoriemetier} from '../../Models/categoriemetier.model';
import {AddprofessionComponent} from '../../Modals/addprofession/addprofession.component';
import {ConfirmationComponent} from '../../Modals/confirmation/confirmation.component';
@Component({
  selector: 'app-profession',
  templateUrl: './profession.component.html',
  styleUrls: ['./profession.component.scss']
})
export class ProfessionComponent implements OnInit {
  tableDataSource = new MatTableDataSource();
  public displayedColumns = ['libelle', 'code', 'categorie', 'revenuemin', 'Actions'];
  clProfession = new Profession();
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  constructor(public dialog: MatDialog, private toastr: ToastrService, private svcePr: ProfessionService) { }

  ngOnInit(): void {
    this.getAllProfessions();
  }
// Récupération des profession
  getAllProfessions = () => {
    this.svcePr.getAllProfession().subscribe(
      (res) => {
        console.log(res);
        if (res !== null && !res['status'] && !res['donnees'] !== null) {
          this.tableDataSource.data = res['donnees'];
          this.tableDataSource.sort = this.sort;
          this.tableDataSource.paginator = this.paginator;
        }
        else if (res !== null && res['status'] && !res['message'] !== null ){
          this.toastr.warning(res['message'], 'Message', {
            timeOut: 3000,
          });
          this.tableDataSource = new MatTableDataSource();
        }
        else{
          this.toastr.error('Oups! Erreur pendant de traitement veuillez contacter l\'administrateur', 'Message', {
            timeOut: 3000,
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  AddOrUpdate = (): void => {
    const dialogRef = this.dialog.open(AddprofessionComponent, {
      width: '600px',
      disableClose: true,
      panelClass: 'myapp-no-padding-dialog',
      position: {top: '5%'},
      data: {
        data: this.clProfession,
        message: 'Vous êtes sur le point de supprimer ' + this.clProfession.libelle + '. Êtes-vous sûr(e) ?'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getAllProfessions();
      }
      console.log(result);
      this.clProfession = new Profession();
    });
  }
  doFilter = (value: string) => {
    this.tableDataSource.filter = value.trim().toLocaleLowerCase();
  }
  update = (clProfession: Profession) => {
    this.clProfession = clProfession;
    this.clProfession.typeAction = 'updated';
    this.AddOrUpdate();
  }
  delete = (clProfession: Profession) => {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '500px',
      disableClose: true,
      panelClass: 'myapp-no-padding-dialog',
      position: {top: '5%'},
      data: {
        message: 'Vous êtes sur le point de supprimer ' + clProfession.libelle + '. Êtes-vous sûr(e) ?'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.svcePr.deleteProfession(clProfession).subscribe(
          (res) => {
            // console.log(res);
            if (res !== null && !res['status'] && !res['donnees'] !== null) {
              this.getAllProfessions();
              this.toastr.success('Opération éffectuée avec succès', 'Message', {
                timeOut: 3000,
              });
            }
            else if (res !== null && res['status'] && !res['message'] !== null ){
              this.toastr.warning(res['message'], 'Message', {
                timeOut: 3000,
              });
            }
            else{
              this.toastr.error('Oups! Erreur pendant de traitement veuillez contacter l\'administrateur', 'Message', {
                timeOut: 3000,
              });
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
    });
  }
}
