import {Action} from './action.model';
export class Actualite extends Action {
  actualiteid: string;
  titre: string;
  introduction: string;
  contenu: Text;
  url: string;
  image: string;
  imageActualite: File;
}
