import {Action} from './action.model';
export class Video extends Action {
  videoid: string;
  titre: string;
  categorie: string;
  commentaire: string;
  video: string;
  videoFile: File;
  thumbailVideo: string;
}
