<div class="card">
  <div class="card-header">
    <h4 class="card-title">Foire à questions</h4>
  </div>
  <hr>
  <div class="card-body">
    <form id="addboutique" #addFAQ="ngForm" action="post" class="form-material m-t-20">
      <div class="row">
        <div class="col-md-12">
          <label>Question</label>
          <div class="form-group">
            <input class="form-control form-control-line"  name="titre" required #titre  [(ngModel)]="this.data['data'].question"  type="text" placeholder="Question... ?">
          </div>
          <label>Reponse</label>
          <div class="form-group">
            <textarea class="form-control form-control-line" style="min-height: 150px"  #reponse name="reponse" required [(ngModel)]="this.data['data'].reponse" placeholder="Ex: Reponse à la question ..."></textarea>
          </div>
        </div>
      </div>
    </form>
  </div>
  <!-- <hr>-->
  <div class="card-footer" style="text-align: right">
    <button class="btn btn-action-orange" style="margin-left: 10px" (click)="close(false)">
      <span class="btn-label">
        <i class="nc-icon nc-simple-remove"></i>
      </span>
      Annuler
    </button>
    <button class="btn btn-action-green margin-left-10" [disabled]="!addFAQ.form.valid" (click)="insertFAQ()" >
      <span class="btn-label">
        <i class="nc-icon nc-check-2"></i>
      </span>
      {{this.data['data'].typeAction === 'add' ? 'Enregistrer': 'Modifier'}}
    </button>
  </div>
</div>
