import {Action} from './action.model';

export class Agence extends Action{
  agenceid: string;
  libelle: string;
  longitude: string;
  lattitude: string;
  contact: string;
  fixe: string;
  commentaire: string;
}
