import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {DeconnexionComponent} from '../../Modals/deconnexion/deconnexion.component';
import { CookieService } from 'ngx-cookie-service';
import * as myGlobals from '../../Tools/staticParams';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {
  private dialogConfig;
  private myInfo = {};
  full: string = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '');
  constructor(private cookieSce: CookieService, private router: Router, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.myInfo = myGlobals.USER_CONNECTED_INFO ;
    const  VAR_USER_CONNECTED_ID = myGlobals.USER_CONNECTED_ID ;
    if (VAR_USER_CONNECTED_ID === null || typeof (VAR_USER_CONNECTED_ID) === 'undefined' || myGlobals.USER_CONNECTED_INFO['codeUser'] === 'TU01' )
    {
      window.localStorage.removeItem('RSTI_USER_CONNECTED_INFO');
      localStorage.removeItem('RSTI_USER_CONNECTED_INFO');
      window.localStorage.removeItem('RSTI_USER_CONNECTED_ID');
      localStorage.removeItem('RSTI_USER_CONNECTED_ID');
      window.location.href = this.full;
    }
    // console.log(typeof (this.myInfo));
  }
  logOut = () => {
    this.dialogConfig = {
      width: '500px',
      disableClose: true,
      position: {top: '10%'}
    };
    const dialogRef = this.dialog.open(DeconnexionComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
        // this.cookieSce.deleteAll('/', '/');
        window.localStorage.removeItem('RSTI_USER_CONNECTED_INFO');
        localStorage.removeItem('RSTI_USER_CONNECTED_INFO');
        window.localStorage.removeItem('RSTI_USER_CONNECTED_ID');
        localStorage.removeItem('RSTI_USER_CONNECTED_ID');
        // this.cookieSce.set('oClinicUser', '', -1);
        // this.router.navigate([this.logindURL]);
        window.location.href = this.full;
      }
    });
  }
}
