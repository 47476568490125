import { Component, OnInit } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {ActualiteService} from '../../Services/actualite.service';
import {AddactualiteComponent} from '../../Modals/addactualite/addactualite.component';
import {Actualite} from '../../Models/actualite.model';
import * as myGlobals from '../../Tools/staticParams';
import {Utilisateur} from "../../Models/utilisateur.model";
import {ConfirmationComponent} from "../../Modals/confirmation/confirmation.component";
import {tsStructureIsReused} from "@angular/compiler-cli/src/transformers/util";

@Component({
  selector: 'app-actualite',
  templateUrl: './actualite.component.html',
  styleUrls: ['./actualite.component.scss']
})
export class ActualiteComponent implements OnInit {
  clActualite = new Actualite();
  listeActualites: Actualite[] = [];
  filePath: string = myGlobals.API_REST_URL_FILE;
  constructor(public dialog: MatDialog, private toastr: ToastrService, private svceActualite: ActualiteService) { }

  ngOnInit(): void {
    this.getAllActualites();
  }
  // Récupération des actualites
  getAllActualites = () => {
    this.svceActualite.getAllActualite().subscribe(
      (res) => {
        console.log(res);
        if (res !== null && !res['status'] && !res['donnees'] !== null) {
          this.listeActualites = res['donnees'] ;
        }
        else if (res !== null && res['status'] && !res['message'] !== null ){
          this.listeActualites = [];
          this.toastr.warning(res['message'], 'Message', {
            timeOut: 3000,
          });
        }
        else{
          this.toastr.error('Oups! Erreur pendant de traitement veuillez contacter l\'administrateur', 'Message', {
            timeOut: 3000,
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  AddOrUpdate = (): void => {
    const dialogRef = this.dialog.open(AddactualiteComponent, {
      width: '600px',
      disableClose: true,
      panelClass: 'myapp-no-padding-dialog',
      position: {top: '5%'},
      data: {
        data: this.clActualite,
        message: 'Vous êtes sur le point de supprimer ' + this.clActualite.titre + '. Êtes-vous sûr(e) ?'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getAllActualites();
      }
      console.log(result);
      this.clActualite = new Actualite();
      // this.animal = result;
    });
  }
  update = (actualite: Actualite) => {
    this.clActualite = actualite;
    this.clActualite.typeAction = 'updated';
    this.AddOrUpdate();
  }
  changeState = (event, actualite: Actualite) => {
    // this.clActualite = actualite;
    actualite.typeAction = 'updated';
    actualite.status = event.checked ? 1 : 2 ;
    // this.delete(actualite);
    this.svceActualite.deleteActualite(actualite).subscribe(
      (res) => {
        // console.log(res);
        if (res !== null && !res['status'] && !res['donnees'] !== null) {
          this.getAllActualites();
          this.toastr.success('Opération éffectuée avec succès', 'Message', {
            timeOut: 3000,
          });
        }
        else if (res !== null && res['status'] && !res['message'] !== null ){
          this.toastr.warning(res['message'], 'Message', {
            timeOut: 3000,
          });
          // actualite.status = actualite.status ? 2 : 1;
        }
        else{
          this.toastr.error('Oups! Erreur pendant de traitement veuillez contacter l\'administrateur', 'Message', {
            timeOut: 3000,
          });
          this.getAllActualites();
        }
      },
      (error) => {
        console.log(error);
        this.getAllActualites();
      }
    );
  }
  delete = (actualite: Actualite) => {
    // actualite.typeAction = 'delete';
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '500px',
      disableClose: true,
      panelClass: 'myapp-no-padding-dialog',
      position: {top: '5%'},
      data: {
        message: 'Vous êtes sur le point de supprimer ' + actualite.titre + '. Êtes-vous sûr(e) ?'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.svceActualite.deleteActualite(actualite).subscribe(
          (res) => {
            // console.log(res);
            if (res !== null && !res['status'] && !res['donnees'] !== null) {
              this.getAllActualites();
              this.toastr.success('Opération éffectuée avec succès', 'Message', {
                timeOut: 3000,
              });
            }
            else if (res !== null && res['status'] && !res['message'] !== null ){
              this.toastr.warning(res['message'], 'Message', {
                timeOut: 3000,
              });
              // actualite.status = actualite.status ? 2 : 1;
            }
            else{
              this.toastr.error('Oups! Erreur pendant de traitement veuillez contacter l\'administrateur', 'Message', {
                timeOut: 3000,
              });
              this.getAllActualites();
            }
          },
          (error) => {
            console.log(error);
            this.getAllActualites();
          }
        );
      }
      else {
        this.getAllActualites();
      }
    });
  }
}
