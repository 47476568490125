import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {FuncService} from '../../Tools/glefunctions';
import * as myGlobals from '../../Tools/staticParams';
import {Utilisateur} from '../../Models/utilisateur.model';
import {AbonneService} from '../../Services/abonne.service';
import {Profession} from '../../Models/profession.model';
import {CategoriemetierService} from '../../Services/categoriemetier.service';

@Component({
  selector: 'app-addabonne',
  templateUrl: './addabonne.component.html',
  styleUrls: ['./addabonne.component.scss']
})
export class AddabonneComponent implements OnInit {
  fileName: string;
  filePath: string = myGlobals.API_REST_URL_FILE;
  @ViewChild('photouser') InputFile: ElementRef;
  listeProfession: Profession[] = [];
  // tslint:disable-next-line:max-line-length
  constructor(@Inject(MAT_DIALOG_DATA) public data: Utilisateur, private funcs: FuncService, public dialogRef: MatDialogRef<AddabonneComponent>, private toastr: ToastrService, private svceAbone: AbonneService, private svcePr: CategoriemetierService) { }


  ngOnInit(): void {
    this.fileName = typeof(this.data['data'].photo) !== 'undefined' && this.data['data'].photo !== '' ?
      this.filePath + 'users/' + this.data['data'].photo : this.filePath + 'default_user.png';
    this.funcs.initDropify(this.fileName);
    this.getAllProfessions();
  }
// Fermerture du dialog
  close = (etat: boolean) => {
    this.dialogRef.close(etat);
  }
  // Récupération des profession
  getAllProfessions = () => {
    this.svcePr.getAllCategoriemetier().subscribe(
      (res) => {
        console.log(res);
        if (res !== null && !res['status'] && !res['donnees'] !== null) {
          this.listeProfession = res['donnees'];
        }
        else if (res !== null && res['status'] && !res['message'] !== null ){
          this.toastr.warning(res['message'], 'Message', {
            timeOut: 3000,
          });
          this.listeProfession = [];
        }
        else{
          this.toastr.error('Oups! Erreur pendant de traitement veuillez contacter l\'administrateur', 'Message', {
            timeOut: 3000,
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  // Ajout d'un abonne
  insertAbonne = () => {
    const file = this.InputFile.nativeElement;
    this.data['data'].photouser = file.files && file.files[0] ?  file.files[0] : '';
    this.svceAbone.addUtilisateur(this.data['data']).subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.toastr.success('Opération éffectuée avec succès', 'Message', {
            timeOut: 3000,
          });
          this.close(true);
        }else {
          this.toastr.warning(res['message'], 'Message', {
            timeOut: 3000,
          });
        }
      },
      (error) => {
        console.log(error);
        this.toastr.error('Oups!!! erreur lors de l\'opération', 'Message', {
          timeOut: 3000,
        });
      }
    );
  }

}
