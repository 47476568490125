import { Component, OnInit } from '@angular/core';
import * as myGlobals from '../../Tools/staticParams';
import {Photo} from '../../Models/photo.model';
import {MatDialog} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {PhotoService} from '../../Services/photo.service';
import {ConfirmationComponent} from '../../Modals/confirmation/confirmation.component';
import {AddphotoComponent} from "../../Modals/addphoto/addphoto.component";

@Component({
  selector: 'app-photo',
  templateUrl: './photo.component.html',
  styleUrls: ['./photo.component.scss']
})
export class PhotoComponent implements OnInit {
  clPhoto = new Photo();
  listePhoto: Photo[] = [] ;
  filePath: string = myGlobals.API_REST_URL_FILE;
  constructor(public dialog: MatDialog, private toastr: ToastrService, private svcePhoto: PhotoService) { }

  ngOnInit(): void {
    this.getAllPhotos();
  }
// Récupération des Photos
  getAllPhotos = () => {
    this.svcePhoto.getAllPhoto().subscribe(
      (res) => {
        console.log(res);
        if (res !== null && !res['status'] && !res['donnees'] !== null) {
          this.listePhoto = res['donnees'];
        }
        else if (res !== null && res['status'] && !res['message'] !== null ){
          this.toastr.warning(res['message'], 'Message', {
            timeOut: 3000,
          });
          this.listePhoto = [];
        }
        else{
          this.toastr.error('Oups! Erreur pendant de traitement veuillez contacter l\'administrateur', 'Message', {
            timeOut: 3000,
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  AddOrUpdate = (): void => {
    const dialogRef = this.dialog.open(AddphotoComponent, {
      width: '500px',
      disableClose: true,
      panelClass: 'myapp-no-padding-dialog',
      position: {top: '5%'},
      data: {
        'data': this.clPhoto
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getAllPhotos();
      }
      // console.log(result);
      this.clPhoto = new Photo();
      // this.animal = result;
    });
  }
  update = (photo: Photo) => {
    this.clPhoto = photo;
    this.clPhoto.typeAction = 'updated';
    this.AddOrUpdate();
  }
  changeState = (event, photo: Photo) => {
    // this.clActualite = actualite;
    photo.typeAction = 'updated';
    photo.status = event.checked ? 1 : 2 ;
    // this.delete(Photo);
    this.svcePhoto.deletePhoto(photo).subscribe(
      (res) => {
        // console.log(res);
        if (res !== null && !res['status'] && !res['donnees'] !== null) {
          this.getAllPhotos();
          this.toastr.success('Opération éffectuée avec succès', 'Message', {
            timeOut: 3000,
          });
        }
        else if (res !== null && res['status'] && !res['message'] !== null ){
          this.toastr.warning(res['message'], 'Message', {
            timeOut: 3000,
          });
          // actualite.status = actualite.status ? 2 : 1;
        }
        else{
          this.toastr.error('Oups! Erreur pendant de traitement veuillez contacter l\'administrateur', 'Message', {
            timeOut: 3000,
          });
          this.getAllPhotos();
        }
      },
      (error) => {
        console.log(error);
        this.getAllPhotos();
      }
    );
  }
  delete = (photo: Photo) => {
    // actualite.typeAction = 'delete';
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '500px',
      disableClose: true,
      panelClass: 'myapp-no-padding-dialog',
      position: {top: '5%'},
      data: {
        message: 'Vous êtes sur le point de supprimer une photo. Êtes-vous sûr(e) ?'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.svcePhoto.deletePhoto(photo).subscribe(
          (res) => {
            // console.log(res);
            if (res !== null && !res['status'] && !res['donnees'] !== null) {
              this.getAllPhotos();
              this.toastr.success('Opération éffectuée avec succès', 'Message', {
                timeOut: 3000,
              });
            }
            else if (res !== null && res['status'] && !res['message'] !== null ){
              this.toastr.warning(res['message'], 'Message', {
                timeOut: 3000,
              });
              // actualite.status = actualite.status ? 2 : 1;
            }
            else{
              this.toastr.error('Oups! Erreur pendant de traitement veuillez contacter l\'administrateur', 'Message', {
                timeOut: 3000,
              });
              this.getAllPhotos();
            }
          },
          (error) => {
            console.log(error);
            this.getAllPhotos();
          }
        );
      }
      else {
        this.getAllPhotos();
      }
    });
  }
}
