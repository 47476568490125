<div class="card">
  <div class="card-header">
    <h4 class="card-title">Confirmation</h4>
  </div>
  <hr>
  <div class="card-body text-center">
    <p>{{data['message']}}</p>
  </div>
   <hr>
  <div class="card-footer" style="text-align: right">
    <button class="btn btn-action-orange" style="margin-left: 10px" (click)="close(false)">
      <span class="btn-label">
        <i class="nc-icon nc-simple-remove"></i>
      </span>
      Annuler
    </button>
    <button class="btn btn-action-green margin-left-10" (click)="close(true)">
      <span class="btn-label">
        <i class="nc-icon nc-check-2"></i>
      </span>
      Valider
    </button>
  </div>
</div>
