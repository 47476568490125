import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {FuncService} from '../../Tools/glefunctions';
import * as myGlobals from '../../Tools/staticParams';
import {Access} from '../../Models/access.model';
import {Typeutilisateur} from '../../Models/typeutilisateur.model';
import {TypeutilisateurService} from '../../Services/typeutilisateur.service';
import {Utilisateur} from '../../Models/utilisateur.model';
import {AccessService} from '../../Services/access.service';
@Component({
  selector: 'app-addacces',
  templateUrl: './addacces.component.html',
  styleUrls: ['./addacces.component.scss']
})
export class AddaccesComponent implements OnInit {
  fileName: string;
  filePath: string = myGlobals.API_REST_URL_FILE;
  @ViewChild('image') InputFile: ElementRef;
  listeTypeUser: Typeutilisateur[] = [];
  constructor(@Inject(MAT_DIALOG_DATA) public data: Utilisateur, private funcs: FuncService, public dialogRef: MatDialogRef<AddaccesComponent>, private toastr: ToastrService, private svceAccess: AccessService, private svceType: TypeutilisateurService) { }

  ngOnInit(): void {
    this.fileName = typeof(this.data['data'].photo) !== 'undefined' && this.data['data'].photo !== '' ?
      this.filePath + 'users/' + this.data['data'].photo : this.filePath + 'default_user.png';
    this.data['data'].cpassword = this.data['data'].password;
    this.funcs.initDropify(this.fileName);
    this.getAllType();
  }
  // Fermerture du dialog
  close = (etat: boolean) => {
    this.dialogRef.close(etat);
  }
  // Récupération des type user
  getAllType = () => {
    this.svceType.getAllTypeutilisateur().subscribe(
      (res) => {
        console.log(res);
        if (res !== null && !res['status'] && !res['donnees'] !== null) {
          this.listeTypeUser = res['donnees'];
          if (this.listeTypeUser !== null && typeof(this.listeTypeUser) !== 'undefined' && this.listeTypeUser.length > 0)
          {
            const found = this.listeTypeUser.find(element => element.code === 'TU08');
            this.data['data'].typeutilisateurid = this.data['data'].typeAction === 'add' && found !== null &&  typeof(found['typeutilisateurid']) !== 'undefined'  ?
              found['typeutilisateurid'] : this.data['data'].typeutilisateurid ;
          }
        }
        else if (res !== null && res['status'] && !res['message'] !== null ){
          this.toastr.warning(res['message'], 'Message', {
            timeOut: 3000,
          });
          this.listeTypeUser = [];
        }
        else{
          this.toastr.error('Oups! Erreur pendant de traitement veuillez contacter l\'administrateur', 'Message', {
            timeOut: 3000,
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  // Ajout d'un abonne
  insertAccess = () => {
    const file = this.InputFile.nativeElement;
    this.data['data'].photouser = file.files && file.files[0] ?  file.files[0] : '';
    if ( this.data['data'].password !== this.data['data'].cpassword){
      this.toastr.info('les mots de passe doivent être identique...', 'Message', {
        timeOut: 3000,
      });
      return;
    }
    const clAccess = new Access();
    clAccess.accessid = this.data['data'].accessid;
    clAccess.password = this.data['data'].password;
    clAccess.username = this.data['data'].tel;
    clAccess.userid = this.data['data'].utilisateurid;
    clAccess.expiredon = this.data['data'].expiredon;
    clAccess.typeAction = 'update';
    this.svceAccess.addAccess(clAccess).subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.toastr.success('Opération éffectuée avec succès', 'Message', {
            timeOut: 3000,
          });
          this.close(true);
        }else {
          this.toastr.warning(res['message'], 'Message', {
            timeOut: 3000,
          });
        }
      },
      (error) => {
        console.log(error);
        this.toastr.error('Oups!!! erreur lors de l\'opération', 'Message', {
          timeOut: 3000,
        });
      }
    );
  }
}
