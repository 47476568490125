import { Component, OnInit } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {Foireaq} from '../../Models/foireaq.model';
import {FoireaqService} from '../../Services/foireaq.service';
import {AddfaqComponent} from '../../Modals/addfaq/addfaq.component';
import {Video} from "../../Models/video.model";
import {Actualite} from "../../Models/actualite.model";
import {ConfirmationComponent} from "../../Modals/confirmation/confirmation.component";
@Component({
  selector: 'app-foireaq',
  templateUrl: './foireaq.component.html',
  styleUrls: ['./foireaq.component.scss']
})
export class FoireaqComponent implements OnInit {
  clFAQ = new Foireaq();
  listeFAQ: Foireaq[] = [];
  constructor(public dialog: MatDialog, private toastr: ToastrService, private svceFoire: FoireaqService) { }

  ngOnInit(): void {
    this.getAllFoire();
  }
// Récupération des foire a questions
  getAllFoire = () => {
    this.svceFoire.getAllFoireaq().subscribe(
      (res) => {
        console.log(res);
        if (res !== null && !res['status'] && !res['donnees'] !== null) {
          this.listeFAQ = res['donnees'] ;
        }
        else if (res !== null && res['status'] && !res['message'] !== null ){
          this.toastr.warning(res['message'], 'Message', {
            timeOut: 3000,
          });
          this.listeFAQ = [];
        }
        else{
          this.toastr.error('Oups! Erreur pendant de traitement veuillez contacter l\'administrateur', 'Message', {
            timeOut: 3000,
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  AddOrUpdate = (): void => {
    const dialogRef = this.dialog.open(AddfaqComponent, {
      width: '600px',
      disableClose: true,
      panelClass: 'myapp-no-padding-dialog',
      position: {top: '5%'},
      data: {
        data: this.clFAQ,
        message: 'Vous êtes sur le point de supprimer ' + this.clFAQ.question + '. Êtes-vous sûr(e) ?'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getAllFoire();
      }
      console.log(result);
      this.clFAQ = new Foireaq();
      // this.animal = result;
    });
  }
  update = (faq: Foireaq) => {
    this.clFAQ = faq;
    this.clFAQ.typeAction = 'updated';
    this.AddOrUpdate();
  }
  changeState = (event, faq: Foireaq) => {
    // this.clActualite = actualite;
    faq.typeAction = 'updated';
    faq.status = event.checked ? 1 : 2 ;
    // this.delete(faq);
    this.svceFoire.deleteFoireaq(faq).subscribe(
      (res) => {
        // console.log(res);
        if (res !== null && !res['status'] && !res['donnees'] !== null) {
          this.getAllFoire();
          this.toastr.success('Opération éffectuée avec succès', 'Message', {
            timeOut: 3000,
          });
        }
        else if (res !== null && res['status'] && !res['message'] !== null ){
          this.toastr.warning(res['message'], 'Message', {
            timeOut: 3000,
          });
          // actualite.status = actualite.status ? 2 : 1;
        }
        else{
          this.toastr.error('Oups! Erreur pendant de traitement veuillez contacter l\'administrateur', 'Message', {
            timeOut: 3000,
          });
          this.getAllFoire();
        }
      },
      (error) => {
        console.log(error);
        this.getAllFoire();
      }
    );
  }
  delete = (faq: Foireaq) => {
    // actualite.typeAction = 'delete';
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '500px',
      disableClose: true,
      panelClass: 'myapp-no-padding-dialog',
      position: {top: '5%'},
      data: {
        message: 'Vous êtes sur le point de supprimer ' + faq.question + '. Êtes-vous sûr(e) ?'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.svceFoire.deleteFoireaq(faq).subscribe(
          (res) => {
            // console.log(res);
            if (res !== null && !res['status'] && !res['donnees'] !== null) {
              this.getAllFoire();
              this.toastr.success('Opération éffectuée avec succès', 'Message', {
                timeOut: 3000,
              });
            }
            else if (res !== null && res['status'] && !res['message'] !== null ){
              this.toastr.warning(res['message'], 'Message', {
                timeOut: 3000,
              });
              // actualite.status = actualite.status ? 2 : 1;
            }
            else{
              this.toastr.error('Oups! Erreur pendant de traitement veuillez contacter l\'administrateur', 'Message', {
                timeOut: 3000,
              });
              this.getAllFoire();
            }
          },
          (error) => {
            console.log(error);
            this.getAllFoire();
          }
        );
      }
      else {
        this.getAllFoire();
      }
    });
  }
}
