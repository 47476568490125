import {Action} from './action.model';

export class PublicationModel extends Action {
  publicationId:string;
  lien:string;
  libelle:string;
  datedebut:string;
  datefin:string;
  url:string;
  statutPublication:number;
  createdby:string ;
  oldfilename:string = '' ;
  createdon: string ;
  status: number ;
}
