<div class="card">
  <div class="card-header">
    <h4 class="card-title">Abonné</h4>
  </div>
  <hr>
  <div class="card-body">
    <form id="addboutique" #addAbonne="ngForm" action="post" class="form-material m-t-20">
      <div class="row">
        <div class="col-md-3 float-left">
          <div class="form-group">
            <input type="file" [(ngModel)]="this.data['data'].photouser" name="photouser" class="form-control form-control-line dropify"  #photouser>
          </div>
        </div>
        <div class="col-md-9">
          <div class="form-group">
            <input class="form-control form-control-line"  name="numcnam" #numcnam  [(ngModel)]="this.data['data'].numcnam"  type="text" autocomplete="off" placeholder="N° CNAM">
          </div>
          <div class="row">
            <div class="col-md-5">
              <div class="form-group">
                <input class="form-control form-control-line" style="text-transform: capitalize"  #nom name="nom" required [(ngModel)]="this.data['data'].nom"  autocomplete="off" type="text" placeholder="Nom">
              </div>
            </div>
            <div class="col-md-7">
              <div class="form-group">
                <input class="form-control form-control-line" style="text-transform: capitalize"  #prenoms name="prenoms" required [(ngModel)]="this.data['data'].prenoms"  autocomplete="off" type="text" placeholder="Prénoms">
              </div>
            </div>
          </div>
          <div class="form-group">
            <input class="form-control form-control-line" style="text-transform: lowercase"  #email name="email" [(ngModel)]="this.data['data'].email"  autocomplete="off" type="text" placeholder="Email...">
          </div>
        </div>
        <div class="col-md-6">
          <label for="">Contact (*)</label>
          <div class="form-group">
            <input class="form-control form-control-line" style="text-transform: lowercase"  #contact name="contact" [dropSpecialCharacters]="true" [showMaskTyped]="true" mask=" 00 00 00 00 00" prefix="(225)" [(ngModel)]="this.data['data'].tel"  type="text" placeholder="Contact...">
          </div>
        </div>
        <div class="col-md-6">
          <label for="">Sexe</label>
          <div class="form-group">
            <select class="form-control form-control-line" #sexe [(ngModel)]="this.data['data'].sexe" name="sexe" >
              <option value="" disabled selected hidden>--Choisir le sexe--</option>
              <option value="M">Masculin</option>
              <option value="F" >Feminin</option>
              <!--<option *ngFor="let item of this.lstTypeProduit" [value]="item.typeproduitid"> {{item.nomtype}}</option>-->
            </select>
          </div>
        </div>
        <div class="col-md-6">
          <label for="">Date de naissance</label>
          <div class="form-group">
            <input class="form-control form-control-line"  #datenaissance name="datenaissance"  autocomplete="off" [(ngModel)]="this.data['data'].datenaissance"  type="date" placeholder="Date de naissance...">
          </div>
        </div>
        <div class="col-md-6">
          <label for="">N° CNI</label>
          <div class="form-group">
            <input class="form-control form-control-line"  #cni  name="cni"  [(ngModel)]="this.data['data'].cni"  autocomplete="off" type="text" placeholder="N° CNI...">
          </div>
        </div>
        <div class="col-md-6">
          <label for="">Ville</label>
          <div class="form-group">
            <input class="form-control form-control-line"  #ville name="ville"  [(ngModel)]="this.data['data'].ville"  autocomplete="off" type="text" placeholder="Ville...">
          </div>
        </div>
        <div class="col-md-6">
          <label for="">Profession</label>
          <!--<div class="form-group">
            <input class="form-control form-control-line"  #profession name="profession" required [(ngModel)]="this.data['data'].profession"  autocomplete="off" type="text" placeholder="Profession...">
          </div>-->
          <div class="form-group">
            <select class="form-control form-control-line" #categoriemetierid [(ngModel)]="this.data['data'].categoriemetierid" name="categoriemetierid" required>
              <option value="" disabled selected hidden>--Choisir la profession--</option>
              <!--<option value="M">Masculin</option>
              <option value="F" >Feminin</option>-->
              <option *ngFor="let item of this.listeProfession" [value]="item.categoriemetierid"> {{item.libelle}}</option>
            </select>
          </div>
        </div>
        <div class="col-md-8">
          <label for="">Adresse</label>
          <div class="form-group">
            <input class="form-control form-control-line"  #adresse name="adresse"  [(ngModel)]="this.data['data'].adresse"  autocomplete="off" type="text" placeholder="Ex: Cocody Riviera Faya ...">
          </div>
        </div>
        <div class="col-md-4">
          <label for="">Debut d'activité</label>
          <div class="form-group">
            <input class="form-control form-control-line"  #debutactivite name="debutactivite" [(ngModel)]="this.data['data'].debutactivite"  type="date" placeholder="Ex: Cocody Riviera Faya ...">
          </div>
        </div>
        <div class="col-md-12">
          <label for="">Descriptif</label>
          <div class="form-group">
            <!--<input class="form-control form-control-line"  #commentaire name="commentaire" required [(ngModel)]="this.data['data'].commentaire"  type="date" >-->
            <textarea class="form-control form-control-line"  #commentaire name="commentaire" [(ngModel)]="this.data['data'].commentaire" autocomplete="off" placeholder="Ex: Abonné non actif ..."></textarea>
          </div>
        </div>
      </div>
    </form>
  </div>
  <!-- <hr>-->
  <div class="card-footer" style="text-align: right">
    <button class="btn btn-action-orange" style="margin-left: 10px" (click)="close(false)">
      <span class="btn-label">
        <i class="nc-icon nc-simple-remove"></i>
      </span>
      Annuler
    </button>
    <button class="btn btn-action-green margin-left-10" [disabled]="!addAbonne.form.valid" (click)="insertAbonne()">
      <span class="btn-label">
        <i class="nc-icon nc-check-2"></i>
      </span>
      {{this.data['data'].typeAction === 'add' ? 'Enregistrer': 'Modifier'}}
    </button>
  </div>
</div>
