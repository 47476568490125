import { MasterdashboardComponent } from './Views/masterdashboard/masterdashboard.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LoginComponent} from './Auth/login/login.component';
import {MainComponent} from './Layout/main/main.component';
import {DashboardComponent} from './Views/dashboard/dashboard.component';
import {VideoComponent} from './Views/video/video.component';
import {AbonneComponent} from './Views/abonne/abonne.component';
import {ActualiteComponent} from './Views/actualite/actualite.component';
import {AgenceComponent} from './Views/agence/agence.component';
import {UtilisateurComponent} from './Views/utilisateur/utilisateur.component';
import {ProfileComponent} from './Views/profile/profile.component';
import {NotfoundComponent} from './404/notfound/notfound.component';
import {FoireaqComponent} from './Views/foireaq/foireaq.component';
import {ProfessionComponent} from './Views/profession/profession.component';
import {CategoriemetierComponent} from './Views/categoriemetier/categoriemetier.component';
import {PhotoComponent} from './Views/photo/photo.component';
import {PublicationComponent} from "./Views/publication/publication.component";

const routes: Routes = [
  { path: 'connexion', component: LoginComponent },
  { path: 'masterdashboard', component: MasterdashboardComponent },
  { path: 'dashboard', component: MainComponent, children: [
      { path: '', component: DashboardComponent},
      { path: 'ListeVideos', component: VideoComponent},
      { path: 'ListePhotos', component: PhotoComponent},
      { path: 'ListeAbonnees', component: AbonneComponent},
      { path: 'ListeActualites', component: ActualiteComponent},
      { path: 'ListeAgences', component: AgenceComponent},
      { path: 'ListeUtilisateurs', component: UtilisateurComponent},
      { path: 'ListeFAQ', component: FoireaqComponent},
      { path: 'ListeProfession', component: ProfessionComponent},
      { path: 'ListeCategorieM', component: CategoriemetierComponent},
      { path: 'userProfile', component: ProfileComponent},
      { path: 'publication', component: PublicationComponent},
    ]
  },
  { path: '', pathMatch: 'full', redirectTo: '/connexion'},
  { path: '**', component: NotfoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
