import { Injectable } from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {LoaderService} from './loader.service';
import {startWith, tap, delay, finalize} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor{

  constructor(public loaderService: LoaderService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    setTimeout(() => {
      this.loaderService.isLoading.next(true);
      return;
    }, 0);
    // this.loaderService.isLoading.next(true);
    return next.handle(req).pipe(
      finalize(
        () => {
          setTimeout(() => {
          this.loaderService.isLoading.next(false);
          }, 0);
        }
      )
    );
  }
}
