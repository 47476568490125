<div class="card">
  <div class="card-header">
    <h4 class="card-title">Vidéo</h4>
  </div>
  <hr>
  <div class="card-body">
    <form id="addboutique" #addVideo="ngForm" action="post" class="form-material m-t-20">
      <div class="row">
        <div class="col-md-12 float-left">
          <!--<div class="form-group">
            <input type="file" [(ngModel)]="this.data['data'].videoFile" name="image" class="form-control form-control-line dropify"  #image>
          </div>-->
          <div class="content  js">

            <div class="box">
              <div class="dropzone"  style="width: 100%">
                <input type="file" required="required" (change)="getThumbail($event.target.files)" name="videoFile" id="file-5" [(ngModel)]="this.data['data'].videoFile" class="inputfile inputfile-4" data-multiple-caption="{count} files selected" multiple #videoFile/>
                <label for="file-5">
                  <i class="fa fa-cloud-upload fa-4x"  style="color: #438f5d;"></i>
                  <h2 style="color: #438f5d;"><label>Upload de fichier</label></h2>
                  <h3 style="color: #438f5d;"><span>Charger le fichier vidéo</span></h3>
                </label>
              </div>
            </div>

          </div>
        </div>
        <!--<div class="col-md-6">
          <label for="">Catégorie</label>
          <div class="form-group">
            <select class="form-control form-control-line" required [(ngModel)]="this.data['data'].categorie"  #categorie name="categorie" required >
              <option value="" disabled selected hidden>&#45;&#45;Choisir le sexe&#45;&#45;</option>
              <option value="M">Masculin</option>
              <option value="F" >Feminin</option>
              &lt;!&ndash;<option *ngFor="let item of this.lstTypeProduit" [value]="item.typeproduitid"> {{item.nomtype}}</option>&ndash;&gt;
            </select>
          </div>
        </div>-->
        <div class="col-md-12">
          <label for="">Description</label>
          <div class="form-group">
            <!--<input class="form-control form-control-line"  #commentaire name="commentaire" required [(ngModel)]="this.data['data'].commentaire"  type="date" >-->
            <textarea class="form-control form-control-line"  #commentaire name="commentaire" required [(ngModel)]="this.data['data'].commentaire" placeholder="Ex: Definir le contenu à affiché ..."></textarea>
          </div>
        </div>
        <div class="col-md-12" id="textImage">

        </div>
      </div>
    </form>
  </div>
  <!-- <hr>-->
  <div class="card-footer" style="text-align: right">
    <button class="btn btn-action-orange" style="margin-left: 10px" (click)="close(false)">
      <span class="btn-label">
        <i class="nc-icon nc-simple-remove"></i>
      </span>
      Annuler
    </button>
    <button class="btn btn-action-green margin-left-10" [disabled]="!addVideo.form.valid" (click)="insertVideo()">
      <span class="btn-label">
        <i class="nc-icon nc-check-2"></i>
      </span>
      Enregistrer
    </button>
  </div>
</div>
