import { DashboardService } from './../../Services/dashboard.service';
import { ToastrService } from 'ngx-toastr';
import {Component, OnInit, ViewChild} from '@angular/core';
import { Dashboard } from 'src/app/Models/dashboard.model';
import {coerceNumberProperty} from "@angular/cdk/coercion";
import {MatTableDataSource} from "@angular/material/table";
import {AbonneService} from "../../Services/abonne.service";
import {MatSort} from "@angular/material/sort";
import {MatPaginator} from "@angular/material/paginator";
import * as myGlobals from "../../Tools/staticParams";
import {DeconnexionComponent} from "../../Modals/deconnexion/deconnexion.component";
import {MatDialog} from "@angular/material/dialog";
import {DatePipe} from "@angular/common";
import {Utilisateur} from "../../Models/utilisateur.model";
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-masterdashboard',
  templateUrl: './masterdashboard.component.html',
  styleUrls: ['./masterdashboard.component.scss']
})
export class MasterdashboardComponent implements OnInit {
  clDashboard =  new Dashboard();
  tableDataSource = new MatTableDataSource();
  fileName: string;
  filePath: string = myGlobals.API_REST_URL_FILE;
  dateMin: any;
  dateMax: any;
  public displayedColumns = ['nom', 'prenoms', 'profession', 'tel'];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  listeInfos = {
    nbreInscrit: 0,
    nbrePhoto: 0,
    nbreVideo: 0,
    nbreActualite: 0,
    nbreAgence: 0,
  };

  listeChartInscrit = [];
  listeChartInscritByMetier = [];
  multi = [];
  treeMap = [];
  view: any[] = [];
  colorScheme = {
    domain: ['#EF7F01', '#00a650', '#034694', '#f8ce18', '#CAEDFB', '#088E39', '#E05A24', '#0E68AA', '#003478', '#088E39', '#99ACC6', '#17A650', '#B4C128', '#FFE77E', '#CAEDFB', '#043478', '#D3350F', '#F8CE18', '#DDEFD9', '#6EB50D']
    /* domain: ['#5AA454', '#A10A28']*/
  };
  private dialogConfig;
  full: string = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '');
  lstUtilisateurAll: Utilisateur[];
  constructor(private datePipe: DatePipe,private dialog: MatDialog, private toastr: ToastrService,  private svceDash: DashboardService, private svceAbone: AbonneService) {
    this.view = [innerWidth / 1.05, 400];
  }

  ngOnInit(): void {
    this.fileName = typeof(myGlobals.USER_CONNECTED_INFO['photo']) !== 'undefined' && myGlobals.USER_CONNECTED_INFO['photo'] !== '' ?
      this.filePath + 'users/' + myGlobals.USER_CONNECTED_INFO['photo'] : this.filePath + 'default_user.png';
    this.getAllInfos();
    this.getAllInscritChart();
    this.getAllInscritByMetierChart();
    this.getAllAbonnes();
  }
  logOut = () => {
    this.dialogConfig = {
      width: '500px',
      disableClose: true,
      position: {top: '10%'}
    };
    const dialogRef = this.dialog.open(DeconnexionComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
        // this.cookieSce.deleteAll('/', '/');
        window.localStorage.removeItem('RSTI_USER_CONNECTED_INFO');
        localStorage.removeItem('RSTI_USER_CONNECTED_INFO');
        window.localStorage.removeItem('RSTI_USER_CONNECTED_ID');
        localStorage.removeItem('RSTI_USER_CONNECTED_ID');
        // this.cookieSce.set('oClinicUser', '', -1);
        // this.router.navigate([this.logindURL]);
        window.location.href = this.full;
      }
    });
  }
  // Récupération des abonnes
  getAllAbonnes = () => {
    this.svceAbone.getAllUtilisateur().subscribe(
      (res) => {
        console.log(res);
        if (res !== null && !res['status'] && !res['donnees'] !== null) {
          this.tableDataSource.data = res['donnees'];
          this.tableDataSource.sort = this.sort;
          this.tableDataSource.paginator = this.paginator;
          this.lstUtilisateurAll = this.tableDataSource.data as Utilisateur[];
        }
        else if (res !== null && res['status'] && !res['message'] !== null ){
          this.toastr.warning(res['message'], 'Message', {
            timeOut: 3000,
          });
          this.tableDataSource = new MatTableDataSource();
        }
        else{
          this.toastr.error('Oups! Erreur pendant de traitement veuillez contacter l\'administrateur', 'Message', {
            timeOut: 3000,
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getAllInfos = () => {
    this.clDashboard.typeAction = 'SelectAllInfos';
    this.svceDash.getSelectAllInfos(this.clDashboard).subscribe(
      (res) => {
        console.log(res);
        if (res !== null && !res['status'] && !res['donnees'] !== null) {
            this.listeInfos.nbreInscrit = Number(res['donnees'][0]['nbreInscrit']) ;
            this.listeInfos.nbrePhoto = Number(res['donnees'][0]['nbrePhoto']);
            this.listeInfos.nbreActualite = Number(res['donnees'][0]['nbreActualite']);
            this.listeInfos.nbreVideo = Number(res['donnees'][0]['nbreVideo']);
            this.listeInfos.nbreAgence = Number(res['donnees'][0]['nbreAgence']);
        }
        else if (res !== null && res['status'] && !res['message'] !== null ){
          this.toastr.warning(res['message'], 'Message', {
            timeOut: 3000,
          });
        }
        else{
          this.toastr.error('Oups! Erreur pendant de traitement veuillez contacter l\'administrateur', 'Message', {
            timeOut: 3000,
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getAllInscritChart = () => {
    this.clDashboard.typeAction = 'SelectAllInscritChart';
    this.svceDash.getSelectAllInfos(this.clDashboard).subscribe(
      (res) => {

        if (res !== null && !res['status'] && !res['donnees'] !== null) {
          const listeSerie = res['donnees'];
          for (const i of listeSerie)
          {
            this.listeChartInscrit.push(i);
          }
          this.listeChartInscrit = [...this.listeChartInscrit];
          // console.log(this.listeChartInscrit);
          this.multi = [
            {
              "name": 'Inscrits',
              "series": this.listeChartInscrit
            }
          ];
        }
        else if (res !== null && res['status'] && !res['message'] !== null ){
          this.toastr.warning(res['message'], 'Message', {
            timeOut: 3000,
          });
        }
        else{
          this.toastr.error('Oups! Erreur pendant de traitement veuillez contacter l\'administrateur', 'Message', {
            timeOut: 3000,
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getAllInscritByMetierChart = () => {
    this.clDashboard.typeAction = 'SelectAllInscritByMetierChart';
    this.svceDash.getSelectAllInfos(this.clDashboard).subscribe(
      (res) => {
        console.log(res);
        if (res !== null && !res['status'] && !res['donnees'] !== null) {
          const listeSerie = res['donnees'];
          for (const i of listeSerie)
          {
            this.listeChartInscritByMetier.push(i);
          }
          this.listeChartInscritByMetier = [...this.listeChartInscritByMetier];
          this.treeMap = this.listeChartInscritByMetier;
        }
        else if (res !== null && res['status'] && !res['message'] !== null ){
          this.toastr.warning(res['message'], 'Message', {
            timeOut: 3000,
          });
        }
        else{
          this.toastr.error('Oups! Erreur pendant de traitement veuillez contacter l\'administrateur', 'Message', {
            timeOut: 3000,
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  labelFormatting(c): string {
    return `${(c.label)}`;
  }
  onResize(event): void {
    this.view = [event.target.innerWidth / 1.35, 400];
  }
  doFilter = (value: string) => {
    this.tableDataSource.filter = value.trim().toLocaleLowerCase();
  }
  doFilterDate = () => {
    const dDate = new Date(this.datePipe.transform(this.dateMin,"yyyy-MM-dd")) ;
    const fDate = new Date(this.datePipe.transform(this.dateMax,"yyyy-MM-dd"));
    if (typeof (this.dateMin) === 'undefined' || typeof (this.dateMax) === 'undefined') {
      return;
    }

    if (fDate.getTime() < dDate.getTime()){
      this.toastr.info('La date de fin doit être superieure à celle de debut s.v.p', 'Message', {
        timeOut: 3000,
      });
      return;
    }
      // this.tableDataSource.data.filter(x => x.profession === '') ;
    this.tableDataSource.data = this.lstUtilisateurAll;
    this.tableDataSource.data = this.tableDataSource.data.filter((item: Utilisateur) => {
      return (new Date(this.datePipe.transform(item.createdon,"yyyy-MM-dd"))).getTime() >= dDate.getTime() &&
        (new Date(this.datePipe.transform(item.createdon,"yyyy-MM-dd"))).getTime() <= fDate.getTime();
    });
  }
  openPDFFeuille = () => {
    const lstFull: any = [];
    for (let i = 0; i < this.tableDataSource.filteredData.length; i++) {
      const lst: Array<string> = [];
      lst.push((this.tableDataSource.filteredData[i]['nom'] === null ? '' : this.tableDataSource.filteredData[i]['nom']));
      lst.push((this.tableDataSource.filteredData[i]['prenoms'] === null ? '' : this.tableDataSource.filteredData[i]['prenoms']));
      lst.push(this.tableDataSource.filteredData[i]['profession'] + ' ' + this.tableDataSource.filteredData[i]['profession']);
      lst.push(this.tableDataSource.filteredData[i]['tel'] === null ? '' : this.tableDataSource.filteredData[i]['tel']);
      lst.push(this.tableDataSource.filteredData[i]['email'] === null ? '' : this.tableDataSource.filteredData[i]['email']);
      lstFull.push(lst);
    }
    const doc = new jsPDF('l', 'mm', 'a4');
    doc.setFont('helvetica');
    doc.setTextColor(150);
    doc.setFontSize(20);
    doc.text( 'Ensemble des inscrits', 5, 10);
    autoTable(doc, {margin: {right: 5, left: 5 } , head: [['Nom', 'Prenom(s)', 'Profession', 'Contact', 'E-mail']], body: lstFull, headStyles: {
        lineColor: [8, 142, 57],
        lineWidth: 0.2,
        fillColor: [8, 142, 57]
      }, theme: 'grid', bodyStyles: {lineColor: [8, 142, 57]}, startY: 20});
    doc.save('inscritsRSTI.pdf');
  }
  exportExcelFeuille = () => {
    /* table id is passed over here */
    // let element = document.getElementById('imprimeTable');
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.tableDataSource.filteredData, {header: ['nom', 'prenoms', 'profession', 'tel', 'email']});
    ws['!ref'] = 'A1:E' + this.tableDataSource.filteredData.length + 1;
    // console.log(ws['!ref']);
    // ws.splice(21, 5);
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, 'inscritsRSTI.xlsx');
  }
}
