import { Component, OnInit } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {VideoService} from '../../Services/video.service';
import {Video} from '../../Models/video.model';
import {AddvideoComponent} from '../../Modals/addvideo/addvideo.component';
import {ConfirmationComponent} from '../../Modals/confirmation/confirmation.component';
import * as myGlobals from '../../Tools/staticParams';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit {
  clVideo = new Video();
  listeVideo: Video[] = [] ;
  filePath: string = myGlobals.API_REST_URL_FILE;
  constructor(public dialog: MatDialog, private toastr: ToastrService, private svceVideo: VideoService) { }

  ngOnInit(): void {
    this.getAllVideos();
  }
  // Récupération des videos
  getAllVideos = () => {
    this.svceVideo.getAllVideo().subscribe(
      (res) => {
        console.log(res);
        if (res !== null && !res['status'] && !res['donnees'] !== null) {
          this.listeVideo = res['donnees'];
        }
        else if (res !== null && res['status'] && !res['message'] !== null ){
          this.toastr.warning(res['message'], 'Message', {
            timeOut: 3000,
          });
          this.listeVideo = [];
        }
        else{
          this.toastr.error('Oups! Erreur pendant de traitement veuillez contacter l\'administrateur', 'Message', {
            timeOut: 3000,
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  AddOrUpdate = (): void => {
    const dialogRef = this.dialog.open(AddvideoComponent, {
      width: '500px',
      disableClose: true,
      panelClass: 'myapp-no-padding-dialog',
      position: {top: '5%'},
      data: {
        'data': this.clVideo
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getAllVideos();
      }
      // console.log(result);
      this.clVideo = new Video();
      // this.animal = result;
    });
  }
  update = (video: Video) => {
    this.clVideo = video;
    this.clVideo.typeAction = 'updated';
    this.AddOrUpdate();
  }
  changeState = (event, video: Video) => {
    // this.clActualite = actualite;
    video.typeAction = 'updated';
    video.status = event.checked ? 1 : 2 ;
    // this.delete(video);
    this.svceVideo.deleteVideo(video).subscribe(
      (res) => {
        // console.log(res);
        if (res !== null && !res['status'] && !res['donnees'] !== null) {
          this.getAllVideos();
          this.toastr.success('Opération éffectuée avec succès', 'Message', {
            timeOut: 3000,
          });
        }
        else if (res !== null && res['status'] && !res['message'] !== null ){
          this.toastr.warning(res['message'], 'Message', {
            timeOut: 3000,
          });
          // actualite.status = actualite.status ? 2 : 1;
        }
        else{
          this.toastr.error('Oups! Erreur pendant de traitement veuillez contacter l\'administrateur', 'Message', {
            timeOut: 3000,
          });
          this.getAllVideos();
        }
      },
      (error) => {
        console.log(error);
        this.getAllVideos();
      }
    );
  }
  delete = (video: Video) => {
    // actualite.typeAction = 'delete';
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '500px',
      disableClose: true,
      panelClass: 'myapp-no-padding-dialog',
      position: {top: '5%'},
      data: {
        message: 'Vous êtes sur le point de supprimer une vidéo. Êtes-vous sûr(e) ?'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.svceVideo.deleteVideo(video).subscribe(
          (res) => {
            // console.log(res);
            if (res !== null && !res['status'] && !res['donnees'] !== null) {
              this.getAllVideos();
              this.toastr.success('Opération éffectuée avec succès', 'Message', {
                timeOut: 3000,
              });
            }
            else if (res !== null && res['status'] && !res['message'] !== null ){
              this.toastr.warning(res['message'], 'Message', {
                timeOut: 3000,
              });
              // actualite.status = actualite.status ? 2 : 1;
            }
            else{
              this.toastr.error('Oups! Erreur pendant de traitement veuillez contacter l\'administrateur', 'Message', {
                timeOut: 3000,
              });
              this.getAllVideos();
            }
          },
          (error) => {
            console.log(error);
            this.getAllVideos();
          }
        );
      }
      else {
        this.getAllVideos();
      }
    });
  }
}
