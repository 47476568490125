<div class="card">
  <div class="card-header">
    <h4 class="card-title">Déconnexion</h4>
  </div>
  <hr>
  <div class="card-body text-center">
    <p class="card-text">
      Vous êtes sur le point de vous deconnectez. Êtes-vous sûr ?
    </p>
  </div>
 <!-- <hr>-->
  <div class="card-footer" style="text-align: right">
    <button class="btn btn-action-green arrow-margin-left" (click)="close(true)">
      <span class="btn-label">
        <i class="nc-icon nc-check-2"></i>
      </span>
      Déconnecter
    </button>
    <button class="btn btn-action-orange" style="margin-left: 10px" (click)="close(false)">
      <span class="btn-label">
        <i class="nc-icon nc-simple-remove"></i>
      </span>
      Annuler
    </button>
  </div>
</div>
