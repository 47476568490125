import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {FuncService} from '../../Tools/glefunctions';
import * as myGlobals from '../../Tools/staticParams';
import {Agence} from '../../Models/agence.model';
import {AgenceService} from '../../Services/agence.service';
@Component({
  selector: 'app-addagence',
  templateUrl: './addagence.component.html',
  styleUrls: ['./addagence.component.scss']
})
export class AddagenceComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: Agence, private funcs: FuncService, public dialogRef: MatDialogRef<AddagenceComponent>, private toastr: ToastrService, private svceAg: AgenceService) { }

  ngOnInit(): void {
  }
  //  Fermerture du dialog
  close = (etat: boolean) => {
    this.dialogRef.close(etat);
  }
  // Ajout d'un abonne
  insertAgence = () => {
    this.svceAg.addAgence(this.data['data']).subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.toastr.success('Opération éffectuée avec succès', 'Message', {
            timeOut: 3000,
          });
          this.close(true);
        }else {
          this.toastr.warning(res['message'], 'Message', {
            timeOut: 3000,
          });
        }
      },
      (error) => {
        console.log(error);
        this.toastr.error('Oups!!! erreur lors de l\'opération', 'Message', {
          timeOut: 3000,
        });
      }
    );
  }
}
