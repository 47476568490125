import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import * as myGlobals from '../Tools/staticParams';
import { throwError, Observable } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import {Access} from '../Models/access.model';

@Injectable({
  providedIn: 'root'
})
export class AccessService {

  controller: string = 'coAccess.php';
  /*Headers*/
  headers = new HttpHeaders().set('Content-Type', 'application/json');
  constructor(private httpQuery: HttpClient) {
    /*  this.headers.append('Content-Type', 'multipart/form-data');
     this.headers.append('Accept', 'application/json'); */
    this.headers.append('Access-Control-Allow-Origin', '*');
  }
  /*Gestion des error HttpClient*/
  handleError = (error: HttpErrorResponse) => {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    // window.alert(errorMessage);
    return throwError(errorMessage);
  }
  // Connexion
  connectUser = (LoginForm: any): Observable<any> => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('username', LoginForm.value.username);
    FORMDATA.append('password', LoginForm.value.password);
    return this.httpQuery.post<any>(`${myGlobals.API_REST_URL_DEV}${this.controller}?Action=${myGlobals.CONNECT_ACTION}`, FORMDATA)
      .pipe(retry(1), catchError(this.handleError));
  }
  // Add
  addAccess =  (access: Access): Observable<Access[]> => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('accessId', typeof (access.accessid) !== 'undefined' ? access.accessid : '');
    FORMDATA.append('username', access.username);
    FORMDATA.append('password', access.password);
    FORMDATA.append('expiredOn', access.expiredon);
    FORMDATA.append('userId', access.userid);
    // tslint:disable-next-line:max-line-length
    FORMDATA.append('createdBy', typeof(access.createdby) === 'undefined' || access.createdby === '' ? myGlobals.USER_CONNECTED_ID : access.createdby);

    return this.httpQuery.post<any>(`${myGlobals.API_REST_URL_DEV}${this.controller}?Action=${access.typeAction === 'add' ? myGlobals.INSERT_ACTION : myGlobals.UPDATEBY_ACTION}`, FORMDATA)
      .pipe(retry(1), catchError(this.handleError));
  }
  // Read
  getAllAccess =  (): Observable<Access[]> => {
    // tslint:disable-next-line:max-line-length
    return this.httpQuery.get<Access[]>(`${myGlobals.API_REST_URL_DEV}${this.controller}?Action=${myGlobals.SELECTALL_ACTION}`, {headers: this.headers})
      .pipe(retry(1), catchError(this.handleError));
  }
  // Update
  updateAccess(id: number, data: Access): Observable<any> {
    const API_URL = `${myGlobals.API_REST_URL_DEV}${this.controller}`;
    return this.httpQuery.put(API_URL, data, { headers: this.headers }).pipe(
      retry(3), catchError(this.handleError)
    );
  }
  // Delete
  deleteAccess(userId: string): Observable<any> {
    const API_URL = `${myGlobals.API_REST_URL_DEV}${this.controller}?Action=${myGlobals.DELETEBY_ACTION }&userId=${userId}`;
    return this.httpQuery.delete(API_URL, { headers: this.headers }).pipe(
      retry(1), catchError(this.handleError)
    );
  }
}
