import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import * as myGlobals from '../Tools/staticParams';
import { throwError, Observable } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import {Agence} from '../Models/agence.model';

@Injectable({
  providedIn: 'root'
})
export class AgenceService {

  controller: string = 'coAgence.php';
  /*Headers*/
  headers = new HttpHeaders().set('Content-Type', 'application/json');
  constructor(private httpQuery: HttpClient) {
    /*  this.headers.append('Content-Type', 'multipart/form-data');
     this.headers.append('Accept', 'application/json'); */
    this.headers.append('Access-Control-Allow-Origin', '*');
  }
  /*Gestion des error HttpClient*/
  handleError = (error: HttpErrorResponse) => {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    // window.alert(errorMessage);
    return throwError(errorMessage);
  }
  // Add
  addAgence =  (agence: Agence): Observable<Agence[]> => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('agenceId', typeof (agence.agenceid) !== 'undefined' ? agence.agenceid : '');
    FORMDATA.append('libelle', agence.libelle);
    FORMDATA.append('contact', agence.contact);
    FORMDATA.append('fixe', agence.fixe);
    FORMDATA.append('longitude', agence.longitude);
    FORMDATA.append('lattitude', agence.lattitude);
    FORMDATA.append('commentaire', agence.commentaire);
    // tslint:disable-next-line:max-line-length
    FORMDATA.append('createdBy', typeof(agence.createdby) === 'undefined' || agence.createdby === '' ? myGlobals.USER_CONNECTED_ID : agence.createdby);

    return this.httpQuery.post<any>(`${myGlobals.API_REST_URL_DEV}${this.controller}?Action=${agence.typeAction === 'add' ? myGlobals.INSERT_ACTION : myGlobals.UPDATEBY_ACTION}`, FORMDATA)
      .pipe(retry(1), catchError(this.handleError));
  }
  // Read
  getAllAgence =  (): Observable<Agence[]> => {
    // tslint:disable-next-line:max-line-length
    return this.httpQuery.get<Agence[]>(`${myGlobals.API_REST_URL_DEV}${this.controller}?Action=${myGlobals.SELECTALL_ACTION}`, {headers: this.headers})
      .pipe(retry(1), catchError(this.handleError));
  }
  // Update
  updateAgence(id: number, data: Agence): Observable<any> {
    const API_URL = `${myGlobals.API_REST_URL_DEV}${this.controller}`;
    return this.httpQuery.put(API_URL, data, { headers: this.headers }).pipe(
      retry(3), catchError(this.handleError)
    );
  }
  // Delete
  deleteAgence(AgenceId: string): Observable<any> {
    const API_URL = `${myGlobals.API_REST_URL_DEV}${this.controller}?Action=${myGlobals.DELETEBY_ACTION }&agenceId=${AgenceId}`;
    return this.httpQuery.delete(API_URL, { headers: this.headers }).pipe(
      retry(1), catchError(this.handleError)
    );
  }
}
