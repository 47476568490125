import {Access} from './access.model';

export class Utilisateur extends Access{
  utilisateurid: string;
  professionid: string;
  categoriemetierid: string;
  cni: string;
  nom: string;
  prenoms: string;
  sexe: string;
  datenaissance: string;
  email: string;
  tel: string;
  numcnam: string;
  ville: string;
  profession: string;
  adresse: string;
  debutactivite: string;
  photo: string;
  typeutilisateurid: string;
  commentaire: string;
  photouser: File;
}
