import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {FuncService} from '../../Tools/glefunctions';
import * as myGlobals from '../../Tools/staticParams';
import {Profession} from '../../Models/profession.model';
import {ProfessionService} from '../../Services/profession.service';
import {CategoriemetierService} from '../../Services/categoriemetier.service';
import {Categoriemetier} from '../../Models/categoriemetier.model';
@Component({
  selector: 'app-addprofession',
  templateUrl: './addprofession.component.html',
  styleUrls: ['./addprofession.component.scss']
})
export class AddprofessionComponent implements OnInit {
  listeMetiercat: Categoriemetier[] = [];
  constructor(@Inject(MAT_DIALOG_DATA) public data: Profession, private funcs: FuncService, public dialogRef: MatDialogRef<AddprofessionComponent>, private toastr: ToastrService, private svcePr: ProfessionService, private svceCat: CategoriemetierService) { }

  ngOnInit(): void {
    this.getAllCategoriemetiers();
  }
  // Fermerture du dialog
  close = (etat: boolean) => {
    this.dialogRef.close(etat);
  }
  // Ajout d'un abonne
  insertProfession = () => {
    this.svcePr.addProfession(this.data['data']).subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.toastr.success('Opération éffectuée avec succès', 'Message', {
            timeOut: 3000,
          });
          this.close(true);
        }else {
          this.toastr.warning(res['message'], 'Message', {
            timeOut: 3000,
          });
        }
      },
      (error) => {
        console.log(error);
        this.toastr.error('Oups!!! erreur lors de l\'opération', 'Message', {
          timeOut: 3000,
        });
      }
    );
  }
  // Récupération des Categoriemetiers
  getAllCategoriemetiers = () => {
    this.svceCat.getAllCategoriemetier().subscribe(
      (res) => {
        console.log(res);
        if (res !== null && !res['status'] && !res['donnees'] !== null) {
          this.listeMetiercat = res['donnees'];
        }
        else if (res !== null && res['status'] && !res['message'] !== null ){
          this.toastr.warning(res['message'], 'Message', {
            timeOut: 3000,
          });
        }
        else{
          this.toastr.error('Oups! Erreur pendant de traitement veuillez contacter l\'administrateur', 'Message', {
            timeOut: 3000,
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
