import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {FuncService} from '../../Tools/glefunctions';
import * as myGlobals from '../../Tools/staticParams';
@Component({
  selector: 'app-detailsabonnes',
  templateUrl: './detailsabonnes.component.html',
  styleUrls: ['./detailsabonnes.component.scss']
})
export class DetailsabonnesComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private funcs: FuncService, public dialogRef: MatDialogRef<DetailsabonnesComponent>, private toastr: ToastrService) { }

  ngOnInit(): void {
  }
// Fermerture du dialog
  close = (etat: boolean) => {
    this.dialogRef.close(etat);
  }
}
