import {Action} from './action.model';

export class Categoriemetier extends Action{
  categoriemetierid: string;
  libelle: string;
  code: string;
  revenuemin: number;
  tauxbase: number;
  tauxcomplement: number;
  cotisationmin: number;
}
