<div class="card">
  <div class="card-header">
    <h4 class="card-title">Photo</h4>
  </div>
  <hr>
  <div class="card-body">
    <form id="addboutique" #addPhoto="ngForm" action="post" class="form-material m-t-20">
      <div class="row">
        <div class="col-md-12 float-left">
          <div class="form-group">
            <input type="file" [(ngModel)]="this.data['data'].imageFile" [attr.required] = "this.data['data'].typeAction === 'add' ? 'required' : null" name="imageFile" class="form-control form-control-line dropify"  #imageFile>
          </div>
        </div>
        <!--<div class="col-md-6">
          <label for="">Catégorie</label>
          <div class="form-group">
            <select class="form-control form-control-line" required [(ngModel)]="this.data['data'].categorie"  #categorie name="categorie" required >
              <option value="" disabled selected hidden>&#45;&#45;Choisir le sexe&#45;&#45;</option>
              <option value="M">Masculin</option>
              <option value="F" >Feminin</option>
              &lt;!&ndash;<option *ngFor="let item of this.lstTypeProduit" [value]="item.typeproduitid"> {{item.nomtype}}</option>&ndash;&gt;
            </select>
          </div>
        </div>-->
        <div class="col-md-12">
          <label for="">Description</label>
          <div class="form-group">
            <!--<input class="form-control form-control-line"  #commentaire name="commentaire" required [(ngModel)]="this.data['data'].commentaire"  type="date" >-->
            <textarea class="form-control form-control-line"  #commentaire name="commentaire" required [(ngModel)]="this.data['data'].commentaire" placeholder="Ex: Definir le contenu à affiché ..."></textarea>
          </div>
        </div>
      </div>
    </form>
  </div>
  <!-- <hr>-->
  <div class="card-footer" style="text-align: right">
    <button class="btn btn-action-orange" style="margin-left: 10px" (click)="close(false)">
      <span class="btn-label">
        <i class="nc-icon nc-simple-remove"></i>
      </span>
      Annuler
    </button>
    <button class="btn btn-action-green margin-left-10" [disabled]="!addPhoto.form.valid" (click)="insertPhoto()">
      <span class="btn-label">
        <i class="nc-icon nc-check-2"></i>
      </span>
      Enregistrer
    </button>
  </div>
</div>
