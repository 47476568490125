<div class="card">
  <div class="card-header">
    <h4 class="card-title">Agence</h4>
  </div>
  <hr>
  <div class="card-body">
    <form id="addboutique" #addAgence="ngForm" action="post" class="form-material m-t-20">
      <div class="row">
          <div class="col-md-12">
            <label>Libéllé</label>
            <div class="form-group">
              <input class="form-control form-control-line"  autocomplete="off" name="libelle" required #libelle  [(ngModel)]="this.data['data'].libelle"  type="text" placeholder="Ex: CNPS cocody 8e Tranche... ">
            </div>
          </div>
          <div class="col-md-6">
            <label>Contact</label>
            <div class="form-group">
              <input class="form-control form-control-line" autocomplete="off"  name="contact" required #contact [dropSpecialCharacters]="true" [showMaskTyped]="true" mask=" 00 00 00 00 00" prefix="(225)"  [(ngModel)]="this.data['data'].contact"  type="text" placeholder="Question... ?">
            </div>
          </div>
          <div class="col-md-6">
            <label>Fixe</label>
            <div class="form-group">
              <input class="form-control form-control-line"  autocomplete="off" name="fixe"  #fixe [dropSpecialCharacters]="true" [showMaskTyped]="true" mask=" 00 00 00 00 00" prefix="(225)"  [(ngModel)]="this.data['data'].fixe"  type="text" placeholder="Question... ?">
            </div>
          </div>
          <div class="col-md-6">
            <label>Longitude</label>
            <div class="form-group">
              <input class="form-control form-control-line"  autocomplete="off" name="longitude"  #longitude  [(ngModel)]="this.data['data'].longitude"  type="text" placeholder="Ex: 9.12">
            </div>
          </div>
          <div class="col-md-6">
            <label>Longitude</label>
            <div class="form-group">
              <input class="form-control form-control-line"  autocomplete="off" name="lattitude"  #lattitude  [(ngModel)]="this.data['data'].lattitude"  type="text" placeholder="Ex: 0.34">
            </div>
          </div>
          <div class="col-md-12">
            <label>Commentaire</label>
            <div class="form-group">
              <textarea class="form-control form-control-line" style="min-height: 150px"  autocomplete="off" #commentaire name="commentaire" [(ngModel)]="this.data['data'].commentaire" placeholder="Ex: Situé non loin du super marché ..."></textarea>
            </div>
          </div>
        </div>
    </form>
  </div>
  <!-- <hr>-->
  <div class="card-footer" style="text-align: right">
    <button class="btn btn-action-orange" style="margin-left: 10px" (click)="close(false)">
      <span class="btn-label">
        <i class="nc-icon nc-simple-remove"></i>
      </span>
      Annuler
    </button>
    <button class="btn btn-action-green margin-left-10"  [disabled]="!addAgence.form.valid" (click)="insertAgence()">
      <span class="btn-label">
        <i class="nc-icon nc-check-2"></i>
      </span>
      Enregistrer
    </button>
  </div>
</div>
