import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {ToastrService} from 'ngx-toastr';
import {MatDialog} from '@angular/material/dialog';
import {AbonneService} from '../../Services/abonne.service';
import {ConfirmationComponent} from '../../Modals/confirmation/confirmation.component';
import {Utilisateur} from '../../Models/utilisateur.model';
import {AddabonneComponent} from '../../Modals/addabonne/addabonne.component';
import {DetailsabonnesComponent} from '../../Modals/detailsabonnes/detailsabonnes.component';
import {utils} from "protractor";

@Component({
  selector: 'app-abonne',
  templateUrl: './abonne.component.html',
  styleUrls: ['./abonne.component.scss']
})
export class AbonneComponent implements OnInit {
  clutilisateur =  new Utilisateur();
  tableDataSource = new MatTableDataSource();
  public displayedColumns = ['nom', 'prenoms', 'ville', 'profession', 'tel', 'Actions'];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  constructor(public dialog: MatDialog, private toastr: ToastrService, private svceAbone: AbonneService) { }

  ngOnInit(): void {
    this.getAllAbonnes();
  }
  // Récupération des abonnes
  getAllAbonnes = () => {
    this.svceAbone.getAllUtilisateur().subscribe(
      (res) => {
        console.log(res);
        if (res !== null && !res['status'] && !res['donnees'] !== null) {
          this.tableDataSource.data = res['donnees'];
          this.tableDataSource.sort = this.sort;
          this.tableDataSource.paginator = this.paginator;
        }
        else if (res !== null && res['status'] && !res['message'] !== null ){
          this.toastr.warning(res['message'], 'Message', {
            timeOut: 3000,
          });
          this.tableDataSource = new MatTableDataSource();
        }
        else{
          this.toastr.error('Oups! Erreur pendant de traitement veuillez contacter l\'administrateur', 'Message', {
            timeOut: 3000,
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  AddOrUpdate = (): void => {
    const dialogRef = this.dialog.open(AddabonneComponent, {
      width: '600px',
      disableClose: true,
      panelClass: 'myapp-no-padding-dialog',
      position: {top: '2%'},
      data: {
        'data': this.clutilisateur,
        'message': 'Vous êtes sur le point de supprimer ' + this.clutilisateur.nom + ' ' + this.clutilisateur.prenoms + '. Êtes-vous sûr(e) ?'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getAllAbonnes();
      }
      console.log(result);
      this.clutilisateur = new Utilisateur();
      // this.animal = result;
    });
  }
  doFilter = (value: string) => {
    this.tableDataSource.filter = value.trim().toLocaleLowerCase();
  }
  update = (utilisateur: Utilisateur) => {
    this.clutilisateur = utilisateur;
    this.clutilisateur.typeAction = 'update';
    this.AddOrUpdate();
  }
  delete = (clutilisateur: Utilisateur) => {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '500px',
      disableClose: true,
      panelClass: 'myapp-no-padding-dialog',
      position: {top: '5%'},
      data: {
        message: 'Vous êtes sur le point de supprimer ' + clutilisateur.nom + ' ' + clutilisateur.prenoms + '. Êtes-vous sûr(e) ?'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.svceAbone.deleteUtilisateur(clutilisateur.utilisateurid).subscribe(
          (res) => {
            // console.log(res);
            if (res !== null && !res['status'] && !res['donnees'] !== null) {
              this.getAllAbonnes();
              this.toastr.success('Opération éffectuée avec succès', 'Message', {
                timeOut: 3000,
              });
            }
            else if (res !== null && res['status'] && !res['message'] !== null ){
              this.toastr.warning(res['message'], 'Message', {
                timeOut: 3000,
              });
            }
            else{
              this.toastr.error('Oups! Erreur pendant de traitement veuillez contacter l\'administrateur', 'Message', {
                timeOut: 3000,
              });
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
    });
  }
  detail = (utilisateur: Utilisateur) => {
    const dialogRef = this.dialog.open(DetailsabonnesComponent, {
      width: '500px',
      disableClose: true,
      panelClass: 'myapp-no-padding-dialog',
      position: {top: '2%'},
      data: {
        data: this.clutilisateur
      }
    });
  }
}
