<div class="sidebar" data-color="white" data-active-color="danger">
  <!--
    Tip 1: You can change the color of the sidebar using: data-color=" default | primary | info | success | warning | danger |"
-->
  <div class="logo">
    <a href="#" class="simple-text logo-mini">
      <div class="logo-image-small">
        <img src="assets/img/logo-small.png">
      </div>
      <!-- <p>CT</p> -->
    </a>
    <a href="#" class="simple-text logo-normal">
      <!--RSTI-CNPS-->
       <div class="logo-image-big">
        <img src="assets/img/logoRSTI.png">
      </div>
    </a>
  </div>
  <div class="sidebar-wrapper">
    <!--<div class="user">
      <div class="photo">
        <img src="../../assets/img/faces/ayo-ogunseinde-2.jpg" />
      </div>
      <div class="info">
        <a data-toggle="collapse" href="#collapseExample" class="collapsed">
              <span>
                Chet Faker
                <b class="caret"></b>
              </span>
        </a>
        <div class="clearfix"></div>
        <div class="collapse" id="collapseExample">
          <ul class="nav">
            <li>
              <a href="#">
                <span class="sidebar-mini-icon">MP</span>
                <span class="sidebar-normal">My Profile</span>
              </a>
            </li>
            <li>
              <a href="#">
                <span class="sidebar-mini-icon">EP</span>
                <span class="sidebar-normal">Edit Profile</span>
              </a>
            </li>
            <li>
              <a href="#">
                <span class="sidebar-mini-icon">S</span>
                <span class="sidebar-normal">Settings</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>-->
    <ul class="nav">
      <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
        <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/dashboard" aria-expanded="false">
          <i class="nc-icon nc-bank"></i>
          <p>Dashboard</p>
        </a>
      </li>
      <li routerLinkActive="active">
        <a routerLinkActive="active" routerLink="ListeAbonnees" aria-expanded="false">
          <i class="nc-icon nc-circle-10"></i>
          <p>Inscrits</p>
        </a>
      </li>
      <li routerLinkActive="active">
        <a routerLinkActive="active" routerLink="ListeActualites" aria-expanded="false">
          <i class="nc-icon nc-world-2"></i>
          <p>Actualites</p>
        </a>
      </li>
      <li routerLinkActive="active">
        <a routerLinkActive="active" routerLink="ListeVideos" aria-expanded="false">
          <i class="nc-icon nc-button-play"></i>
          <p>Vidéos</p>
        </a>
      </li>
      <li routerLinkActive="active">
        <a routerLinkActive="active" routerLink="ListePhotos" aria-expanded="false">
          <i class="nc-icon nc-image"></i>
          <p>Photos</p>
        </a>
      </li>
      <li routerLinkActive="active">
        <a routerLinkActive="active" routerLink="ListeAgences" aria-expanded="false">
          <i class="nc-icon nc-pin-3"></i>
          <p>Agences</p>
        </a>
      </li>
      <li routerLinkActive="active">
        <a routerLinkActive="active" routerLink="ListeFAQ" aria-expanded="false">
          <i class="nc-icon nc-alert-circle-i"></i>
          <p>Listes FAQ</p>
        </a>
      </li>

      <li routerLinkActive="active">
        <a routerLinkActive="active" routerLink="ListeProfession" aria-expanded="false">
          <i class="nc-icon nc-briefcase-24"></i>
          <p>Professions</p>
        </a>
      </li>

      <li routerLinkActive="active">
        <a routerLinkActive="active" routerLink="ListeCategorieM" aria-expanded="false">
          <i class="nc-icon nc-chart-pie-36"></i>
          <p>Catégorie M</p>
        </a>
      </li>
      <li routerLinkActive="active">
        <a routerLinkActive="active" routerLink="ListeUtilisateurs" aria-expanded="false">
          <i class="nc-icon nc-single-02"></i>
          <p>Utilisateurs</p>
        </a>
      </li>

      <li routerLinkActive="active">
        <a routerLinkActive="active" routerLink="publication" aria-expanded="false">
          <i class="nc-icon nc-single-02"></i>
          <p>Publications</p>
        </a>
      </li>
    </ul>
  </div>
</div>
