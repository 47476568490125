import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {Utilisateur} from '../../Models/utilisateur.model';
import {ToastrService} from 'ngx-toastr';
import {AbonneService} from '../../Services/abonne.service';
import {DashboardService} from "../../Services/dashboard.service";
import {coerceNumberProperty} from "@angular/cdk/coercion";
import {Dashboard} from "../../Models/dashboard.model";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  clutilisateur =  new Utilisateur();
  clDashboard =  new Dashboard();
  listeInfos = {
    nbreInscrit: 0,
    nbrePhoto: 0,
    nbreVideo: 0,
    nbreActualite: 0,
    nbreAgence: 0,
  };
  listeChartInscrit = [];
  listeChartInscritByMetier = [];
  tableDataSource = new MatTableDataSource();
  view: any[] = [];

  saleData = [
    { name: 'Mobiles', value: 105000 },
    { name: 'Laptop', value: 55000 },
    { name: 'AC', value: 15000 },
    { name: 'Headset', value: 150000 },
    { name: 'Fridge', value: 20000 }
  ];

  multi = [
    /*{
      "name": 'Germany',
      "series": [
        {
          "name": "2010",
          "value": 5300000
        },
        {
          "name": "2011",
          "value": 8940000
        },
        {
          "name": "2012",
          "value": 2440000
        },
        {
          "name": "2013",
          "value": 3270000
        }
      ]
    },

    {
      "name": "USA",
      "series": [
        {
          "name": "2010",
          "value": 7870000
        },
        {
          "name": "2011",
          "value": 8270000
        },
        {
          "name": "2012",
          "value": 140000
        },
        {
          "name": "2013",
          "value": 7270000
        }
      ]
    },

     {
       "name": "France",
       "series": [
         {
           "name": "2010",
           "value": 5000002
         },
         {
           "name": "2011",
           "value": 5800000
         },
         {
           "name": "2012",
           "value": 1640000
         },
         {
           "name": "2013",
           "value": 5270000
         }
       ]
     }*/
  ];
  treeMap = [
    /* {
      "name": "Artisans",
      "value": 8940000
    },
    {
      "name": "Consultants",
      "value": 5000000
    },
    {
      "name": "Commerçant",
      "value": 7200000
    },
    {
      "name": "Architect",
      "value": 4500000
    },
    {
      "name": "Pêcheurs",
      "value": 5730000
    },{
      "name": "Transporteurs",
      "value": 8200000
    } */
  ];
  colorScheme = {
    domain: ['#EF7F01', '#00a650', '#034694', '#f8ce18', '#CAEDFB', '#088E39', '#E05A24', '#0E68AA', '#003478', '#088E39', '#99ACC6', '#17A650', '#B4C128', '#FFE77E', '#CAEDFB', '#043478', '#D3350F', '#F8CE18', '#DDEFD9', '#6EB50D']
   /* domain: ['#5AA454', '#A10A28']*/
  };
  public displayedColumns = ['numcmam', 'nom', 'prenoms', 'sexe', 'ville', 'profession', 'tel', 'activite', 'Actions'];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  constructor(private toastr: ToastrService, private svceAbone: AbonneService, private svceDash: DashboardService) {
    this.view = [innerWidth / 1.18, 400];
  }
  ngOnInit(): void {
    /* this.chartLine();
    this.chartPie(); */
    this.getAllInfos();
    this.getAllInscritChart();
    this.getAllInscritByMetierChart();
    // console.log(innerWidth);
  }
  getAllInfos = () => {
    this.clDashboard.typeAction = 'SelectAllInfos';
    this.svceDash.getSelectAllInfos(this.clDashboard).subscribe(
      (res) => {
        console.log(res);
        if (res !== null && !res['status'] && !res['donnees'] !== null) {
            this.listeInfos.nbreInscrit = Number(res['donnees'][0]['nbreInscrit']) ;
            this.listeInfos.nbrePhoto = Number(res['donnees'][0]['nbrePhoto']);
            this.listeInfos.nbreActualite = Number(res['donnees'][0]['nbreActualite']);
            this.listeInfos.nbreVideo = Number(res['donnees'][0]['nbreVideo']);
            this.listeInfos.nbreAgence = Number(res['donnees'][0]['nbreAgence']);
        }
        else if (res !== null && res['status'] && !res['message'] !== null ){
          this.toastr.warning(res['message'], 'Message', {
            timeOut: 3000,
          });
        }
        else{
          this.toastr.error('Oups! Erreur pendant de traitement veuillez contacter l\'administrateur', 'Message', {
            timeOut: 3000,
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getAllInscritChart = () => {
    this.clDashboard.typeAction = 'SelectAllInscritChart';
    this.svceDash.getSelectAllInfos(this.clDashboard).subscribe(
      (res) => {

        if (res !== null && !res['status'] && !res['donnees'] !== null) {
          const listeSerie = res['donnees'];
          for (const i of listeSerie)
          {
            this.listeChartInscrit.push(i);
          }
          this.listeChartInscrit = [...this.listeChartInscrit];
          // console.log(this.listeChartInscrit);
          this.multi = [
            {
              name: 'Inscrits',
              series: this.listeChartInscrit
            }
          ];
        }
        else if (res !== null && res['status'] && !res['message'] !== null ){
          this.toastr.warning(res['message'], 'Message', {
            timeOut: 3000,
          });
        }
        else{
          this.toastr.error('Oups! Erreur pendant de traitement veuillez contacter l\'administrateur', 'Message', {
            timeOut: 3000,
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getAllInscritByMetierChart = () => {
    this.clDashboard.typeAction = 'SelectAllInscritByMetierChart';
    this.svceDash.getSelectAllInfos(this.clDashboard).subscribe(
      (res) => {
        console.log(res);
        if (res !== null && !res['status'] && !res['donnees'] !== null) {
          const listeSerie = res['donnees'];
          for (const i of listeSerie)
          {
            this.listeChartInscritByMetier.push(i);
          }
          this.listeChartInscritByMetier = [...this.listeChartInscritByMetier];
          this.treeMap = this.listeChartInscritByMetier;
        }
        else if (res !== null && res['status'] && !res['message'] !== null ){
          this.toastr.warning(res['message'], 'Message', {
            timeOut: 3000,
          });
        }
        else{
          this.toastr.error('Oups! Erreur pendant de traitement veuillez contacter l\'administrateur', 'Message', {
            timeOut: 3000,
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  labelFormatting(c): string {
    return `${(c.label)}`;
  }
  onResize(event): void {
    this.view = [event.target.innerWidth / 1.35, 400];
  }
  // Récupération des abonnes
  getAllAbonnes = () => {
    this.svceAbone.getAllUtilisateur().subscribe(
      (res) => {
        console.log(res);
        if (res !== null && !res['status'] && !res['donnees'] !== null) {
          this.tableDataSource.data = res['donnees'];
          this.tableDataSource.sort = this.sort;
          this.tableDataSource.paginator = this.paginator;
        }
        else if (res !== null && res['status'] && !res['message'] !== null ){
          this.toastr.warning(res['message'], 'Message', {
            timeOut: 3000,
          });
        }
        else{
          this.toastr.error('Oups! Erreur pendant de traitement veuillez contacter l\'administrateur', 'Message', {
            timeOut: 3000,
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  chartLine = () => {
    /*const chartColor = '#FFFFFF';
    const ctx = document.getElementById('chartStock').getContext('2d');
    const gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
    gradientStroke.addColorStop(0, '#80b6f4');
    gradientStroke.addColorStop(1, chartColor);

    const  gradientFill = ctx.createLinearGradient(0, 170, 0, 50);
    gradientFill.addColorStop(0, 'rgba(128, 182, 244, 0)');
    gradientFill.addColorStop(1, 'rgba(249, 99, 59, 0.40)');

    const myChart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: ['6pm', '9pm', '11pm', '2am', '4am', '6am', '8am'],
        datasets: [{
          label: 'Active Users',
          borderColor: '#f17e5d',
          pointBackgroundColor: '#f17e5d',
          pointRadius: 3,
          pointHoverRadius: 3,
          lineTension: 0,
          fill: false,
          borderWidth: 3,
          data: [200, 250, 300, 350, 280, 330, 400]
        }]
      },
      options: {

        legend: {

          display: false
        },

        tooltips: {
          enabled: false
        },

        scales: {
          yAxes: [{

            ticks: {
              fontColor: '#9f9f9f',
              beginAtZero: false,
              maxTicksLimit: 5,
            },
            gridLines: {
              drawBorder: false,
              borderDash: [8, 5],
              zeroLineColor: 'transparent',
              color: '#9f9f9f'
            }

          }],

          xAxes: [{
            barPercentage: 1.6,
            gridLines: {
              drawBorder: false,
              borderDash: [8, 5],
              color: '#9f9f9f',
              zeroLineColor: 'transparent'
            },
            ticks: {
              padding: 20,
              fontColor: '#9f9f9f'
            }
          }]
        },
      }
    });*/
  }
  chartPie = () => {
    /*const ctx = document.getElementById('chartEmail').getContext("2d");

    const myChart = new Chart(ctx, {
      type: 'pie',
      data: {
        labels: [1, 2, 3],
        datasets: [{
          label: 'Emails',
          pointRadius: 0,
          pointHoverRadius: 0,
          backgroundColor: [
            '#e3e3e3',
            '#4acccd',
            '#fcc468'
          ],
          borderWidth: 0,
          data: [542, 480, 430]
        }]
      },
      options: {

        legend: {

          display: false
        },

        tooltips: {
          enabled: false
        },

        scales: {
          yAxes: [{

            ticks: {
              display: false
            },
            gridLines: {
              drawBorder: false,
              zeroLineColor: 'transparent',
              color: 'rgba(255,255,255,0.05)'
            }

          }],

          xAxes: [{
            barPercentage: 1.6,
            gridLines: {
              drawBorder: false,
              color: 'rgba(255,255,255,0.1)',
              zeroLineColor: 'transparent'
            },
            ticks: {
              display: false,
            }
          }]
        },
      }
    });*/
  }
  doFilter = (value: string) => {
    this.tableDataSource.filter = value.trim().toLocaleLowerCase();
  }
}
