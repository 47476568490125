import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {FuncService} from '../../Tools/glefunctions';
import * as myGlobals from '../../Tools/staticParams';
import {Actualite} from '../../Models/actualite.model';
import {ActualiteService} from "../../Services/actualite.service";
@Component({
  selector: 'app-addactualite',
  templateUrl: './addactualite.component.html',
  styleUrls: ['./addactualite.component.scss']
})
export class AddactualiteComponent implements OnInit {
  fileName: string;
  filePath: string = myGlobals.API_REST_URL_FILE;
  @ViewChild('imageActualite') InputFile: ElementRef;
  // tslint:disable-next-line:max-line-length
  constructor(@Inject(MAT_DIALOG_DATA) public data: Actualite, private funcs: FuncService, public dialogRef: MatDialogRef<AddactualiteComponent>, private toastr: ToastrService, private svceAct: ActualiteService) { }

  ngOnInit(): void {
    this.fileName = typeof(this.data['data'].image) !== 'undefined' && this.data['data'].image !== '' ?
      this.filePath + 'actualites/' + this.data['data'].image : this.filePath + 'default_user.png';
    this.funcs.initDropify(this.fileName);
  }
  // Fermerture du dialog
  close = (etat: boolean) => {
    this.dialogRef.close(etat);
  }
// Ajout d'un abonne
  insertActualite = () => {
    const file = this.InputFile.nativeElement;
    this.data['data'].imageActualite = file.files && file.files[0] ?  file.files[0] : '';
    this.svceAct.addActualite(this.data['data']).subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.toastr.success('Opération éffectuée avec succès', 'Message', {
            timeOut: 3000,
          });
          this.close(true);
        }else {
          this.toastr.warning(res['message'], 'Message', {
            timeOut: 3000,
          });
        }
      },
      (error) => {
        console.log(error);
        this.toastr.error('Oups!!! erreur lors de l\'opération', 'Message', {
          timeOut: 3000,
        });
      }
    );
  }
}
