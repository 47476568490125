import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import * as myGlobals from '../Tools/staticParams';
import { throwError, Observable } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import {Actualite} from '../Models/actualite.model';
@Injectable({
  providedIn: 'root'
})
export class ActualiteService {

  controller: string = 'coActualite.php';
  /*Headers*/
  headers = new HttpHeaders().set('Content-Type', 'application/json');
  constructor(private httpQuery: HttpClient) {
    /*  this.headers.append('Content-Type', 'multipart/form-data');
     this.headers.append('Accept', 'application/json'); */
    this.headers.append('Access-Control-Allow-Origin', '*');
  }
  /*Gestion des error HttpClient*/
  handleError = (error: HttpErrorResponse) => {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    // window.alert(errorMessage);
    return throwError(errorMessage);
  }
  // Add
  addActualite =  (actualite: Actualite): Observable<Actualite[]> => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('actualiteId', typeof (actualite.actualiteid) !== 'undefined' ? actualite.actualiteid : '');
    FORMDATA.append('titre', actualite.titre);
    FORMDATA.append('introduction', actualite.introduction);
    FORMDATA.append('contenu', actualite.contenu);
    FORMDATA.append('image', actualite.image);
    FORMDATA.append('url', actualite.url);
    FORMDATA.append('imageActualite', actualite.imageActualite);
    // tslint:disable-next-line:max-line-length
    FORMDATA.append('createdBy', typeof(actualite.createdby) === 'undefined' || actualite.createdby === '' ? myGlobals.USER_CONNECTED_ID : actualite.createdby);

    return this.httpQuery.post<any>(`${myGlobals.API_REST_URL_DEV}${this.controller}?Action=${actualite.typeAction === 'add' ? myGlobals.INSERT_ACTION : myGlobals.UPDATEBY_ACTION}`, FORMDATA)
      .pipe(retry(1), catchError(this.handleError));
  }
  // Read
  getAllActualite =  (): Observable<Actualite[]> => {
    // tslint:disable-next-line:max-line-length
    return this.httpQuery.get<Actualite[]>(`${myGlobals.API_REST_URL_DEV}${this.controller}?Action=${myGlobals.SELECTALL_ACTION}`, {headers: this.headers})
      .pipe(retry(1), catchError(this.handleError));
  }
  // Update
  updateActualite(id: number, data: Actualite): Observable<any> {
    const API_URL = `${myGlobals.API_REST_URL_DEV}${this.controller}`;
    return this.httpQuery.put(API_URL, data, { headers: this.headers }).pipe(
      retry(3), catchError(this.handleError)
    );
  }
  // Delete
  deleteActualite(actualite: Actualite): Observable<any> {
    const API_URL = `${myGlobals.API_REST_URL_DEV}${this.controller}?Action=${myGlobals.DELETEBY_ACTION }&actualiteId=${actualite.actualiteid}&status=${actualite.typeAction === 'delete' ? 0 : actualite.status}`;
    return this.httpQuery.delete(API_URL, { headers: this.headers }).pipe(
      retry(1), catchError(this.handleError)
    );
  }
}
