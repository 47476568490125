import {Action} from './action.model';

export class Photo extends Action{
  imageid: string;
  titre: string;
  categorie: string;
  commentaire: string;
  image: string;
  imageFile: File;
}
