<div class="card">
  <div class="card-header">
    <h4 class="card-title">Publication</h4>
  </div>
  <hr>

  <div class="card-body">
    <form id="addboutique" #addPhoto="ngForm" action="post" class="form-material m-t-20">
      <div class="row mb-4">
        <div class="col-md-12 float-left mb-4">
          <div class="form-group">
            <input type="file" [(ngModel)]="this.data['publication'].lien" [attr.required] = "this.data['publication'].typeAction === 'add' ? 'required' : null" name="lien" class="form-control form-control-line dropify"  #lien>
          </div>
        </div>

        <div class="row col-md-12 justify-content-center mb-4">
          <div class="col-md-12">
            <div class="form-group">
              <input class="form-control form-control-line"  autocomplete="off" name="libelle" required #libelle  [(ngModel)]="data['publication'].libelle"  type="text" placeholder="Ex: CNPS cocody 8e Tranche... ">
            </div>
          </div>
        </div>

        <div class="row col-md-12 mb-4">
          <div class="col-md-6">
            <div class="form-group">
              <label>Date debut</label>
              <input required type="date" class="form-control form-control-line" [(ngModel)]="data['publication'].datedebut" name="datedebut" placeholder="Date debut" #datedebut>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Date fin</label>
              <input required type="date" class="form-control form-control-line" [(ngModel)]="data['publication'].datefin" name="datefin" placeholder="Date fin" #datefin>
            </div>
          </div>
        </div>

        <div class="row col-md-12">
          <div class="col-md-8">
            <div class="form-group">
              <input type="text" [(ngModel)]="data['publication'].url" name="url" class="form-control form-control-line" placeholder="Lien pour d'avantage d'informations" #url>
            </div>
          </div>
          <div class="col-md-4 justify-content-center align-content-center">
            <div class="form-group">
              <input type="checkbox" name="owned" [checked]="data['publication'].statutPublication == 1" [value]="data['publication'].statutPublication"  id="md_checkbox_25" (change)="statutPublication($event)"
                     class="filled-in chk-col-purple">
              <label for="md_checkbox_25">Voulez-vous activer la publication ?</label>
            </div>
          </div>
        </div>

        <!--<div class="row">
          <div class="col-md-8">
            <label>Libéllé</label>
            <div class="form-group">
              <input class="form-control form-control-line"  autocomplete="off" name="libelle" required #libelle  [(ngModel)]="data['publication'].libelle"  type="text" placeholder="Ex: CNPS cocody 8e Tranche... ">
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label class="text-center p-2 control-label col-md-6">Date debut</label>
              <div class="col-md-6">
              <input required type="date" class="form-control form-control-line" [(ngModel)]="data['publication'].datedebut" name="datedebut" placeholder="Date debut" #datedebut>
            </div>
             &lt;!&ndash; <mat-form-field class="form-group">
                <input matInput [matDatepicker]="picker1" [(ngModel)]="data['publication'].datedebut" [min]="toDay" name="datedebut" readonly placeholder="Date Début" required #datedebut>
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
              </mat-form-field>&ndash;&gt;
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label class="text-center p-2 control-label col-md-6">Date fin</label>
              <div class="col-md-6">
                <input required type="date" class="form-control form-control-line" [(ngModel)]="data['publication'].datefin" name="datefin" placeholder="Date fin" #datefin>
              </div>
             &lt;!&ndash; <mat-form-field class="form-group">
                <input matInput [matDatepicker]="picker2" [(ngModel)]="data['publication'].datefin" [min]="data['publication'].datedebut" name="dateMax" readonly placeholder="Date Fin" required #dateMax>
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
              </mat-form-field>&ndash;&gt;
            </div>
          </div>
          <div class="col-md-8">
            <div class="form-group">
              <input type="text" [(ngModel)]="data['publication'].url" name="url" class="form-control form-control-line" placeholder="Lien pour d'avantage d'informations" #url>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="form-group">
              <input type="checkbox" name="owned" [checked]="data['publication'].statutPublication == 1" [value]="data['publication'].statutPublication"  id="md_checkbox_25" (change)="statutPublication($event)"
                     class="filled-in chk-col-purple">
              <label for="md_checkbox_25">Voulez-vous activer la publication ?</label>
            </div>
          </div>
        </div>-->
      </div>
    </form>
  </div>
  <div class="card-footer" style="text-align: right">
    <button class="btn btn-action-orange" style="margin-left: 10px" (click)="close(false)">
      <span class="btn-label">
        <i class="nc-icon nc-simple-remove"></i>
      </span>
      Annuler
    </button>
    <button class="btn btn-action-green margin-left-10" [disabled]="!addPhoto.form.valid" (click)="onClick(data['publication'])">
      <span class="btn-label">
        <i class="nc-icon nc-check-2"></i>
      </span>
      Enregistrer
    </button>
  </div>
</div>
