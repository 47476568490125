<div class="card">
  <div class="card-header">
    <h4 class="card-title"> Liste des publications</h4>
  </div>
  <div class="card-body">
    <div class="table-responsive">
      <div class="row">
        <div class="col-md-6">
          <div class="row" style="width: 100%; float: left">
            <div class="col-md-12">
              <div id="example23_filter" class="dataTables_filter form-horizontal row">
                <label class="col-md-1 col-form-label" style="float: left">Recherche :</label>
                <div class="col-md-9" style="float: left">
                  <div class="form-group">
                    <input type="search" (keyup)="doFilter($event.target.value)" class="form-control" placeholder="Rechercher..." aria-controls="Rechercher">
                  </div>
                </div>
                <!--<label>Recherche:<input type="search" (keyup)="doFilter($event.target.value)" class="form-control" placeholder="" aria-controls="example23"></label>-->
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6" style="text-align: right">
          <div class="button_add">
            <button class="btn btn-action-orange waves-effect" (click)="this.cleanFields();AddOrUpdate(this.publication)" type="button">
              <span class="btn-label">
                <i class="nc-icon nc-simple-add"></i>
              </span>
              Nouveau
            </button>
          </div>
        </div>
      </div>
      <table mat-table class="table table-bordered table-striped dataTable table table-responsive-md" cellspacing="0" width="100%" role="grid" aria-describedby="example23_info" style="width: 100%;" [dataSource]="tableDataSource" matSort>

        <tr role="row" class="even">
          <ng-container matColumnDef="lien" role="row" class="even">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Annonce </th>
            <td mat-cell *matCellDef="let element"> {{element.libelle}} </td>
          </ng-container>
        </tr>
        <tr role="row" class="even">
          <ng-container matColumnDef="datedebut" role="row" class="even">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Date debut </th>
            <td mat-cell *matCellDef="let element"> {{element.datedebut}} </td>
          </ng-container>
        </tr>
        <tr role="row" class="even">
          <ng-container matColumnDef="datefin" role="row" class="even">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Date fin </th>
            <td mat-cell *matCellDef="let element"> {{element.datefin}} </td>
          </ng-container>
        </tr>
        <tr role="row" class="even">
          <ng-container matColumnDef="url" role="row" class="even">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> URL </th>
            <td mat-cell *matCellDef="let element">
              <span class="text-info" *ngIf="(element.url == '' || element.url == null|| element.url == undefined)">{{element.url != null || element.url !== undefined ? element.url : 'Aucune url sur cette annonce'}}</span>
              <span class="text-info" *ngIf="(element.url != '' && element.url != null && element.url != undefined)"> <a href="{{element.url}}" [target]="'_blank'"> {{element.url}} </a></span>
            </td>
          </ng-container>
        </tr>

        <tr role="row" class="even">
          <ng-container matColumnDef="statutPublication" role="row" class="even">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Statut </th>
            <td mat-cell *matCellDef="let element">
              <mat-slide-toggle [ngClass]="element.statutPublication == 1 ? 'actif' : 'inactif'" (click)="redirectToChangeStatut(element.statutPublication == 1 ? 0 : 1,element.publicationId, statut)" [checked]="element.statutPublication == 1" #statut>
                <h5 style="margin: 0!important;" [ngClass]="element.statutPublication == 1 ? 'text-success' : 'text-danger'">{{element.statutPublication == 1 ? 'Actif' : 'Inactif'}}</h5>
              </mat-slide-toggle>
            </td>
          </ng-container>
        </tr>
        <tr role="row" class="even">
          <ng-container matColumnDef="Actions">
            <th mat-header-cell *matHeaderCellDef style="width: 2%;text-align: center"> Actions </th>
            <td mat-cell *matCellDef=" let element" style="width:2%;text-align: center">
              <div class="dropdown">
                <button class="dropdown-toggle btn btn-action-green  btn-block" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i class="nc-icon nc-align-center"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                  <!--<div class="dropdown-header">Dropdown header</div>
                  <a class="dropdown-item" (click)="detail(element)" href="javascript:void(0);">Voir details</a>-->
                  <a class="dropdown-item" (click)="update(element)" href="javascript:void(0);">Modifier</a>
                  <a class="dropdown-item" (click)="delete(element)" href="javascript:void(0);">Supprimer</a>
                </div>
              </div>
            </td>
          </ng-container>
        </tr>
        <tr role="row" class="even">
          <ng-container matColumnDef="Loading">
            <th class="w-75" mat-footer-cell *matFooterCellDef colspan="9" style="text-align: center;">
              Chargement en cour....
            </th>
          </ng-container>
        </tr>
        <tr role="row" class="even">
          <ng-container matColumnDef="NoData">
            <th class="w-75" mat-footer-cell *matFooterCellDef colspan="9" style="text-align: center;">
              Aucunes données trouvées....
            </th>
          </ng-container>
        </tr>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <tr mat-footer-row *matFooterRowDef="['Loading']" [ngClass]="{'hide': tableDataSource != null }"></tr>
        <tr mat-footer-row *matFooterRowDef="['NoData']" [ngClass]="{'hide': !(tableDataSource != null && tableDataSource.data.length==0)}"></tr>
      </table>
      <mat-paginator [pageSizeOptions]="[10, 20, 40]" [pageSize]="10" showFirstLastButtons></mat-paginator>
    </div>
  </div>
</div>
