import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import * as myGlobals from '../Tools/staticParams';
import { throwError, Observable } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import {Utilisateur} from '../Models/utilisateur.model';

@Injectable({
  providedIn: 'root'
})
export class UtilisateurService {

  controller: string = 'coUtilisateur.php';
  /*Headers*/
  headers = new HttpHeaders().set('Content-Type', 'application/json');
  constructor(private httpQuery: HttpClient) {
    /*  this.headers.append('Content-Type', 'multipart/form-data');
     this.headers.append('Accept', 'application/json'); */
    this.headers.append('Access-Control-Allow-Origin', '*');
  }
  /*Gestion des error HttpClient*/
  handleError = (error: HttpErrorResponse) => {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    // window.alert(errorMessage);
    return throwError(errorMessage);
  }
  // Add
  addUtilisateur =  (utilisateur: Utilisateur): Observable<Utilisateur[]> => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('utilisateurId', typeof (utilisateur.utilisateurid) !== 'undefined' ? utilisateur.utilisateurid : '');
    FORMDATA.append('nom', utilisateur.nom);
    FORMDATA.append('prenoms', utilisateur.prenoms);
    FORMDATA.append('tel', utilisateur.tel);
    FORMDATA.append('email', utilisateur.email);
    FORMDATA.append('typeutilisateurid', utilisateur.typeutilisateurid);
    FORMDATA.append('numcnam', utilisateur.numcnam);
    FORMDATA.append('username', typeof(utilisateur.username) !== 'undefined' ? utilisateur.username : utilisateur.tel);
    FORMDATA.append('password', utilisateur.password);
    FORMDATA.append('expiredon', utilisateur.expiredon);
    FORMDATA.append('typeutilisateurid', utilisateur.typeutilisateurid);
    FORMDATA.append('photo', utilisateur.photo !== 'null' ? utilisateur.photo : '');
    FORMDATA.append('photouser', utilisateur.photouser);
    // tslint:disable-next-line:max-line-length
    FORMDATA.append('createdby', typeof(utilisateur.createdby) === 'undefined' || utilisateur.createdby === '' ? myGlobals.USER_CONNECTED_ID : utilisateur.createdby);

    return this.httpQuery.post<any>(`${myGlobals.API_REST_URL_DEV}${this.controller}?Action=${utilisateur.typeAction === 'add' ? myGlobals.INSERT_ACTION : myGlobals.UPDATEBY_ACTION}`, FORMDATA)
      .pipe(retry(1), catchError(this.handleError));
  }

  addUtilisateurAdmin =  (utilisateur: Utilisateur): Observable<Utilisateur[]> => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('utilisateurId', typeof (utilisateur.utilisateurid) !== 'undefined' ? utilisateur.utilisateurid : '');
    FORMDATA.append('nom', utilisateur.nom);
    FORMDATA.append('prenoms', utilisateur.prenoms);
    FORMDATA.append('tel', utilisateur.tel);
    FORMDATA.append('email', utilisateur.email);
    FORMDATA.append('typeutilisateurid', utilisateur.typeutilisateurid);
    FORMDATA.append('numcnam', utilisateur.numcnam);
    FORMDATA.append('username', utilisateur.username);
    FORMDATA.append('password', utilisateur.password);
    FORMDATA.append('expiredon', utilisateur.expiredon);
    /*    FORMDATA.append('cni', utilisateur.cni);
        FORMDATA.append('datenaissance', utilisateur.datenaissance);
        FORMDATA.append('debutactivite', utilisateur.debutactivite);
        FORMDATA.append('ville', utilisateur.ville);
        FORMDATA.append('profession', utilisateur.profession);
        FORMDATA.append('adresse', utilisateur.adresse);
        FORMDATA.append('typeutilisateurid', utilisateur.typeutilisateurid);*/
    FORMDATA.append('photo', utilisateur.photo !== 'null' ? utilisateur.photo : '');
    FORMDATA.append('photouser', utilisateur.photouser);
    // tslint:disable-next-line:max-line-length
    FORMDATA.append('createdby', typeof(utilisateur.createdby) === 'undefined' || utilisateur.createdby === '' ? myGlobals.USER_CONNECTED_ID : utilisateur.createdby);

    return this.httpQuery.post<any>(`${myGlobals.API_REST_URL_DEV}${this.controller}?Action=${utilisateur.typeAction === 'add' ? 'UserInsert' : myGlobals.UPDATEBY_ACTION } `, FORMDATA)
      .pipe(retry(1), catchError(this.handleError));
  }
  // Read
  getAllUtilisateur =  (): Observable<Utilisateur[]> => {
    // tslint:disable-next-line:max-line-length
    return this.httpQuery.get<Utilisateur[]>(`${myGlobals.API_REST_URL_DEV}${this.controller}?Action=${myGlobals.SELECTALLDATA_ACTION}`, {headers: this.headers})
      .pipe(retry(1), catchError(this.handleError));
  }
  // Update
  updateUtilisateur(id: number, data: Utilisateur): Observable<any> {
    const API_URL = `${myGlobals.API_REST_URL_DEV}${this.controller}`;
    return this.httpQuery.put(API_URL, data, { headers: this.headers }).pipe(
      retry(3), catchError(this.handleError)
    );
  }
  // Delete
  deleteUtilisateur(userId: string): Observable<any> {
    const API_URL = `${myGlobals.API_REST_URL_DEV}${this.controller}?Action=${myGlobals.DELETEBY_ACTION }&utilisateurId=${userId}`;
    return this.httpQuery.delete(API_URL, { headers: this.headers }).pipe(
      retry(1), catchError(this.handleError)
    );
  }
}
