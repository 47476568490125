import {Action} from './action.model';

export class Access extends Action{
   id: number;
   accessid: string;
   username: string;
   password: string;
   cpassword: string;
   expiredon: string;
   userid: string;
}
