import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {AccessService} from '../../Services/access.service';
import {Location} from '@angular/common';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  LoginForm = new FormGroup({
    username: new FormControl(''),
    password: new FormControl(''),
  });
  constructor(private toastr: ToastrService, private svceAuth: AccessService, private location: Location) { }

  ngOnInit(): void {
  }
  OnLogin = () => {
    this.location.replaceState('/'); // clears browser history so they can't navigate with back button

    this.svceAuth.connectUser(this.LoginForm).subscribe(
      (res) => {
        if (res !== null && res['donnees'] !== null && !res['status']){
          localStorage.setItem('RSTI_USER_CONNECTED_INFO', JSON.stringify(res['donnees']));
          localStorage.setItem('RSTI_USER_CONNECTED_ID', res['donnees']['utilisateurid']);
          // this.utilisateur = res['donnees'];
          if ( String(res['donnees']['codeUser']) === 'TU08' ) {
          window.location.href = 'dashboard';
          }
          else {
            window.location.href = 'masterdashboard';
          }
        }
        else{
          this.toastr.warning('Mot de passe ou contact invalide', 'Message', {
            timeOut: 3000,
          });
        }
      }
      ,
      (error) => {
        console.log(error);
        this.toastr.error('Oups!!! Erreur lors de la connexion.', 'Message', {
          timeOut: 3000,
        });
      });
  }
}
