import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {FuncService} from '../../Tools/glefunctions';
import * as myGlobals from '../../Tools/staticParams';
import {Foireaq} from "../../Models/foireaq.model";
import {FoireaqService} from "../../Services/foireaq.service";
@Component({
  selector: 'app-addfaq',
  templateUrl: './addfaq.component.html',
  styleUrls: ['./addfaq.component.scss']
})
export class AddfaqComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: Foireaq, private funcs: FuncService, public dialogRef: MatDialogRef<AddfaqComponent>, private toastr: ToastrService, private svcefaq: FoireaqService) { }

  ngOnInit(): void {
  }
// Fermerture du dialog
  close = (etat: boolean) => {
    this.dialogRef.close(etat);
  }
  // Ajout d'une FAQ
  insertFAQ = () => {
    this.svcefaq.addFoireaq(this.data['data']).subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.toastr.success('Opération éffectuée avec succès', 'Message', {
            timeOut: 3000,
          });
          this.close(true);
        }else {
          this.toastr.warning(res['message'], 'Message', {
            timeOut: 3000,
          });
        }
      },
      (error) => {
        console.log(error);
        this.toastr.error('Oups!!! erreur lors de l\'opération', 'Message', {
          timeOut: 3000,
        });
      }
    );
  }
}
