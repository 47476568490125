<div class="row margin-bottom-20">
  <div class="col-lg-3 col-md-6 col-sm-6">
    <div class="card card-stats">
      <div class="card-body ">
        <div class="row">
          <div class="col-5 col-md-4">
            <div class="icon-big text-center text-OurOrange">
              <i class="nc-icon nc-circle-10 text-OurOrange"></i>
            </div>
          </div>
          <div class="col-7 col-md-8">
            <div class="numbers">
              <p class="card-category">Inscrits</p>
              <p class="card-title">{{ listeInfos.nbreInscrit < 10 ? '0'+listeInfos.nbreInscrit : listeInfos.nbreInscrit}}<p>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer ">
        <hr>
        <div class="stats">
          <i class="fa fa-refresh"></i>
          Actualisé
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-3 col-md-6 col-sm-6">
    <div class="card card-stats">
      <div class="card-body ">
        <div class="row">
          <div class="col-5 col-md-4">
            <div class="icon-big text-center icon-warning">
              <i class="nc-icon nc-image text-Oursuccess"></i>
            </div>
          </div>
          <div class="col-7 col-md-8">
            <div class="numbers">
              <p class="card-category">Photos</p>
              <p class="card-title">{{ listeInfos.nbrePhoto < 10 ? '0'+listeInfos.nbrePhoto : listeInfos.nbrePhoto}}<p>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer ">
        <hr>
        <div class="stats">
          <i class="fa fa-calendar-o"></i>
          Hier
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-3 col-md-6 col-sm-6">
    <div class="card card-stats">
      <div class="card-body ">
        <div class="row">
          <div class="col-5 col-md-4">
            <div class="icon-big text-center icon-warning">
              <i class="nc-icon nc-button-play text-OurRed"></i>
            </div>
          </div>
          <div class="col-7 col-md-8">
            <div class="numbers">
              <p class="card-category">Vidéos</p>
              <p class="card-title">{{ listeInfos.nbreVideo < 10 ? '0'+listeInfos.nbreVideo : listeInfos.nbreVideo}}<p>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer ">
        <hr>
        <div class="stats">
          <i class="fa fa-clock-o"></i>
          il y a 1 heure
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-3 col-md-6 col-sm-6">
    <div class="card card-stats">
      <div class="card-body ">
        <div class="row">
          <div class="col-5 col-md-4">
            <div class="icon-big text-center icon-warning">
              <i class="nc-icon nc-pin-3 text-OurBlue"></i>
            </div>
          </div>
          <div class="col-7 col-md-8">
            <div class="numbers">
              <p class="card-category">Agences</p>
              <p class="card-title">{{ listeInfos.nbreAgence < 10 ? '0'+listeInfos.nbreAgence : listeInfos.nbreAgence}}<p>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer ">
        <hr>
        <div class="stats">
          <i class="fa fa-refresh"></i>
          Actualisé
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row  margin-bottom-20">
  <div class="col-md-12 margin-bottom-20">
    <div class="card card-chart">
      <div class="card-header">
        <h5 class="card-title">Inscriptions: RSTI</h5>
        <p class="card-category">Courbe d'évolution des inscriptions par période (Jours - Mois - Années)</p>
      </div>
      <div class="card-body">
        <!--<ngx-charts-bar-vertical
          [view]="[1000,400]"
          [results]="saleData"
          [xAxisLabel]="'Products'"
          [legendTitle]="'Product Sale Chart'"
          [yAxisLabel]="'Sale'"
          [legend]="false"
          [showXAxisLabel]="true"
          [showYAxisLabel]="true"
          [xAxis]="true"
          [yAxis]="true"
          [gradient]="true">
        </ngx-charts-bar-vertical>-->
        <div style="display: inline-block">
          <ngx-charts-line-chart
            (window:resize)="onResize($event)"
            [view]="view"
            [scheme]="colorScheme"
            [results]="multi"
            [gradient]="false"
            [xAxis]="true"
            [yAxis]="true"
            [legend]="false"
            [showXAxisLabel]="false"
            [showYAxisLabel]="false"
            [xAxisLabel]="'Number'"
            [yAxisLabel]="'Value'"
            [autoScale]="true"
            [timeline]="true">
          </ngx-charts-line-chart>
        </div>
      </div>
      <div class="card-footer ">
        <!--<div class="legend margin-bottom-10">
          <i class="fa fa-circle text-primary"></i> Open
        </div>-->
        <hr>
        <div class="stats margin-top-10">
          <i class="fa fa-refresh"></i>
          Actualisé
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-12">
    <div class="card ">
      <div class="card-header ">
        <h5 class="card-title">Inscriptions</h5>
        <p class="card-category">Evolution des inscriptions par métier</p>
      </div>
      <div class="card-body ">
        <!--<ngx-charts-pie-chart
          [results]="saleData"
          [legend]="false"
          [legendTitle]="'Legend'"
          [view]="[520,365]"
          [labels]="true" >
        </ngx-charts-pie-chart>-->
        <ngx-charts-tree-map
          (window:resize)="onResize($event)"
          [view]="view"
          [scheme]="colorScheme"
          [results]="treeMap"
          [gradient]="false"
          [animations]="true"
          [labelFormatting]="labelFormatting">
        </ngx-charts-tree-map>
      </div>
      <div class="card-footer ">
        <!--<div class="legend margin-bottom-10">
          <i class="fa fa-circle text-primary"></i> Open
        </div>-->
        <hr>
        <div class="stats margin-top-10">
          <i class="fa fa-refresh"></i>
          Actualisé
        </div>
      </div>
    </div>
  </div>
</div>
<!--<div class="row  margin-bottom-20">
  <div class="col-lg-4 col-md-6 col-sm-6">
    <div class="card card-stats">
      <div class="card-body ">
        <div class="row">
          <div class="col-5 col-md-4">
            <div class="icon-big text-center icon-warning">
              <i class="nc-icon nc-favourite-28 text-primary"></i>
            </div>
          </div>
          <div class="col-7 col-md-8">
            <div class="numbers">
              <p class="card-category">Total cotisations</p>
              <p class="card-title">40,000, 050<p>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer ">
        <hr>
        <div class="stats">
          <i class="fa fa-refresh"></i>
          Atualisé
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-4 col-md-6 col-sm-6">
    <div class="card card-stats">
      <div class="card-body ">
        <div class="row">
          <div class="col-5 col-md-4">
            <div class="icon-big text-center icon-warning">
              <i class="nc-icon nc-money-coins text-success"></i>
            </div>
          </div>
          <div class="col-7 col-md-8">
            <div class="numbers">
              <p class="card-category">Cotisations de base</p>
              <p class="card-title">1O,345,789<p>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer ">
        <hr>
        <div class="stats">
          <i class="fa fa-calendar-o"></i>
          Last day
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-4 col-md-6 col-sm-6">
    <div class="card card-stats">
      <div class="card-body ">
        <div class="row">
          <div class="col-5 col-md-4">
            <div class="icon-big text-center icon-warning">
              <i class="nc-icon nc-money-coins text-success"></i>
            </div>
          </div>
          <div class="col-7 col-md-8">
            <div class="numbers">
              <p class="card-category">Cotisations complémentaires</p>
              <p class="card-title">1O,345,789<p>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer ">
        <hr>
        <div class="stats">
          <i class="fa fa-calendar-o"></i>
          Last day
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header">
        <h5 class="card-title">inscrits</h5>
        <p class="card-category">Evolution des inscriptions</p>
      </div>
      <div class="card-body">
        <div style="display: inline-block">
          <ngx-charts-bar-vertical
            (window:resize)="onResize($event)"
            [view]="view"
            [results]="saleData"
            [xAxisLabel]="'Products'"
            [legendTitle]="'Product Sale Chart'"
            [yAxisLabel]="'Sale'"
            [legend]="false"
            [showXAxisLabel]="false"
            [showYAxisLabel]="false"
            [xAxis]="true"
            [yAxis]="true"
            [gradient]="true">
          </ngx-charts-bar-vertical>
        </div>
       &lt;!&ndash; <div class="table-responsive">
          <div id="example23_filter" class="dataTables_filter form-horizontal row">
            <label class="col-md-1 col-form-label" style="float: left;text-align: left">Recherche :</label>
            <div class="col-md-6" style="float: left">
              <div class="form-group">
                <input type="search" (keyup)="doFilter($event.target.value)" class="form-control" placeholder="Rechercher..." aria-controls="Rechercher">
              </div>
            </div>
          </div>
          <table mat-table class="table table-bordered table-striped dataTable table table-responsive-md" cellspacing="0" width="100%" role="grid" aria-describedby="example23_info" style="width: 100%;" [dataSource]="tableDataSource" matSort>
            <tr role="row" class="even">
              <ng-container matColumnDef="numcmam" role="row" class="even">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> N° CNAM </th>
                <td mat-cell *matCellDef="let element"> {{ element.numcnam === null || element.numcnam === '' ? 'Non définit' : element.numcmam}} </td>
              </ng-container>
            </tr>
            <tr role="row" class="even">
              <ng-container matColumnDef="nom" role="row" class="even">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Nom </th>
                <td mat-cell *matCellDef="let element"> {{element.nom}} </td>
              </ng-container>
            </tr>
            <tr role="row" class="even">
              <ng-container matColumnDef="prenoms" role="row" class="even">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Prénom(s) </th>
                <td mat-cell *matCellDef="let element"> {{element.prenoms}} </td>
              </ng-container>
            </tr>
            <tr role="row" class="even">
              <ng-container matColumnDef="sexe" role="row" class="even">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Sexe </th>
                <td mat-cell *matCellDef="let element"> {{element.sexe === 'M' ? 'Masculin' : 'Feminin'}} </td>
              </ng-container>
            </tr>
            <tr role="row" class="even">
              <ng-container matColumnDef="ville" role="row" class="even">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Ville </th>
                <td mat-cell *matCellDef="let element"> {{element.ville}} </td>
              </ng-container>
            </tr>
            <tr role="row" class="even">
              <ng-container matColumnDef="profession" role="row" class="even">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Profession </th>
                <td mat-cell *matCellDef="let element"> {{element.profession}} </td>
              </ng-container>
            </tr>
            <tr role="row" class="even">
              <ng-container matColumnDef="tel" role="row" class="even">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Contact </th>
                <td mat-cell *matCellDef="let element"> {{element.tel | mask: '00 00 00 00 00'}} </td>
              </ng-container>
            </tr>
            <tr role="row" class="even">
              <ng-container matColumnDef="activite" role="row" class="even">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Activite </th>
                <td mat-cell *matCellDef="let element"> {{element.debutactivite}} </td>
              </ng-container>
            </tr>
            <tr role="row" class="even">
              <ng-container matColumnDef="Actions">
                <th mat-header-cell *matHeaderCellDef style="width: 2%;text-align: center"> Actions </th>
                <td mat-cell *matCellDef=" let element" style="width:2%;text-align: center">
                  <div class="dropdown">
                    <button class="dropdown-toggle btn btn-primary  btn-block" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i class="nc-icon nc-align-center"></i>
                    </button>
                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                      &lt;!&ndash;<div class="dropdown-header">Dropdown header</div>&ndash;&gt;
                      <a class="dropdown-item" (click)="detail(element)" href="javascript:void(0);">Voir details</a>
                      <a class="dropdown-item" (click)="update(element)" href="javascript:void(0);">Modifier</a>
                      <a class="dropdown-item" (click)="delete(element)" href="javascript:void(0);">Supprimer</a>
                    </div>
                  </div>
                </td>
              </ng-container>
            </tr>
            <tr role="row" class="even">
              <ng-container matColumnDef="Loading">
                <th class="w-75" mat-footer-cell *matFooterCellDef colspan="9" style="text-align: center;">
                  Chargement en cour....
                </th>
              </ng-container>
            </tr>
            <tr role="row" class="even">
              <ng-container matColumnDef="NoData">
                <th class="w-75" mat-footer-cell *matFooterCellDef colspan="9" style="text-align: center;">
                  Aucunes données trouvées....
                </th>
              </ng-container>
            </tr>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            <tr mat-footer-row *matFooterRowDef="['Loading']" [ngClass]="{'hide': tableDataSource != null }"></tr>
            <tr mat-footer-row *matFooterRowDef="['NoData']" [ngClass]="{'hide': !(tableDataSource != null && tableDataSource.data.length==0)}"></tr>
          </table>
          <mat-paginator [pageSizeOptions]="[5, 10, 20, 40]" [pageSize]="5" showFirstLastButtons></mat-paginator>
        </div>&ndash;&gt;
       </div>
      <div class="card-footer ">
        &lt;!&ndash;<div class="legend margin-bottom-10">
          <i class="fa fa-circle text-primary"></i> Open
        </div>&ndash;&gt;
        <hr>
        <div class="stats margin-top-10">
          <i class="fa fa-refresh"></i>
          Atualisé
        </div>
      </div>
    </div>
  </div>
</div>-->
