import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {FuncService} from '../../Tools/glefunctions';
import * as myGlobals from '../../Tools/staticParams';
import {Categoriemetier} from '../../Models/categoriemetier.model';
import {CategoriemetierService} from '../../Services/categoriemetier.service';

@Component({
  selector: 'app-addcategoriemetier',
  templateUrl: './addcategoriemetier.component.html',
  styleUrls: ['./addcategoriemetier.component.scss']
})
export class AddcategoriemetierComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: Categoriemetier, private funcs: FuncService, public dialogRef: MatDialogRef<AddcategoriemetierComponent>, private toastr: ToastrService, private svceCatM: CategoriemetierService ) { }

  ngOnInit(): void {
  }
  // Fermerture du dialog
  close = (etat: boolean) => {
    this.dialogRef.close(etat);
  }
  // Ajout d'un abonne
  insertCategorieM = () => {
    this.svceCatM.addCategoriemetier(this.data['data']).subscribe(
      (res) => {
        if (res !== null && !res['status']){
          this.toastr.success('Opération éffectuée avec succès', 'Message', {
            timeOut: 3000,
          });
          this.close(true);
        }else {
          this.toastr.warning(res['message'], 'Message', {
            timeOut: 3000,
          });
        }
      },
      (error) => {
        console.log(error);
        this.toastr.error('Oups!!! erreur lors de l\'opération', 'Message', {
          timeOut: 3000,
        });
      }
    );
  }
}
