import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from "@angular/common/http";
import {Observable, throwError} from "rxjs";
import * as myGlobals from "../Tools/staticParams";
import {catchError, retry} from "rxjs/operators";
import {PublicationModel} from "../Models/publication.model";

@Injectable({
  providedIn: 'root'
})
export class PublicationService {

  controller: string = 'coPublication.php';
  /*Headers*/
  headers = new HttpHeaders().set('Content-Type', 'application/json');
  constructor(private httpQuery: HttpClient) {
    /*  this.headers.append('Content-Type', 'multipart/form-data');
     this.headers.append('Accept', 'application/json'); */
    this.headers.append('Access-Control-Allow-Origin', '*');
  }
  /*Gestion des error HttpClient*/
  handleError = (error: HttpErrorResponse) => {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    // window.alert(errorMessage);
    return throwError(errorMessage);
  }
  // Add
  addPublication =  (publication: PublicationModel): Observable<PublicationModel[]> => {
    const FORMDATA: any = new FormData();
    // tslint:disable-next-line:max-line-length
    FORMDATA.append('publicationId', publication.publicationId);
    FORMDATA.append('libelle', publication.libelle);
    FORMDATA.append('lien', publication.lien);
    FORMDATA.append('datedebut', publication.datedebut);
    FORMDATA.append('datefin', publication.datefin);
    FORMDATA.append('url', publication.url);
    FORMDATA.append('statutPublication', publication.statutPublication);
    FORMDATA.append('oldfilename', publication.oldfilename);
    // tslint:disable-next-line:max-line-length
    FORMDATA.append('createdby', typeof(publication.createdby) === 'undefined' || publication.createdby === '' ? myGlobals.USER_CONNECTED_ID : publication.createdby);

    return this.httpQuery.post<any>(`${myGlobals.API_REST_URL_DEV}${this.controller}?Action=${publication.typeAction === 'add' ? myGlobals.INSERT_ACTION : myGlobals.UPDATEBY_ACTION}`, FORMDATA)
      .pipe(retry(1), catchError(this.handleError));
  }
  // Read
  getAllPublication =  (): Observable<PublicationModel[]> => {
    // tslint:disable-next-line:max-line-length
    return this.httpQuery.get<PublicationModel[]>(`${myGlobals.API_REST_URL_DEV}${this.controller}?Action=${myGlobals.SELECTALL_ACTION}`, {headers: this.headers})
      .pipe(retry(1), catchError(this.handleError));
  }
  // Update
  updatePublication(id: number, data: PublicationModel): Observable<any> {
    const API_URL = `${myGlobals.API_REST_URL_DEV}${this.controller}`;
    return this.httpQuery.put(API_URL, data, { headers: this.headers }).pipe(
      retry(3), catchError(this.handleError)
    );
  }
  // Delete
  deletePublication(publicationid: string): Observable<any> {
    const API_URL = `${myGlobals.API_REST_URL_DEV}${this.controller}?Action=${myGlobals.DELETEBY_ACTION }&publicationid=${publicationid}`;
    return this.httpQuery.delete(API_URL, { headers: this.headers }).pipe(
      retry(1), catchError(this.handleError)
    );
  }

  activerOrDesactiverPublication = (statutPublication: number, publicationId: string) => {
    const FORMDATA: any = new FormData();
    FORMDATA.append('publicationid', publicationId);
    FORMDATA.append('statutPublication', statutPublication);
    return this.httpQuery.post<any>(myGlobals.API_REST_URL_DEV+this.controller+ '?Action=ChangeStatut', FORMDATA)
      .pipe(retry(1), catchError(this.handleError));
  }
}
