import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import * as myGlobals from "../../Tools/staticParams";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FuncService} from "../../Tools/glefunctions";
import {ToastrService} from "ngx-toastr";
import {PublicationService} from "../../Services/publication.service";
import {PublicationModel} from "../../Models/publication.model";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-addpublication',
  templateUrl: './addpublication.component.html',
  styleUrls: ['./addpublication.component.scss']
})
export class AddpublicationComponent implements OnInit {
  toDay: Date = new Date();
  fileName: string;
  filePath: string = myGlobals.API_REST_URL_FILE;
  @ViewChild('lien') InputFile: ElementRef;
  statutPublicationChecked = 0;
  constructor(@Inject(MAT_DIALOG_DATA) public data: PublicationModel,
              private datePipe: DatePipe, private funcs: FuncService, public dialogRef: MatDialogRef<AddpublicationComponent>, private toastr: ToastrService, private svcePublication: PublicationService) { }


  ngOnInit(): void {
    this.fileName = typeof (this.data['publication'].lien) !== 'undefined' && this.data['publication'].lien !== '' ?
      this.filePath + 'images/' + this.data['publication'].lien : this.filePath + 'default_user.png';
    this.funcs.initDropify(this.fileName);
    this.statutPublicationChecked = this.data[`publication`].statutPublication;
  }
  close = (etat: boolean) => {
    this.dialogRef.close(etat);
  }
  statutPublication = (e) => {
    if (e.target.checked){
      this.statutPublicationChecked = 1;
    }else{
      this.statutPublicationChecked = 0;
    }
  }
  onClick = (publication: PublicationModel) => {
    const file = this.InputFile.nativeElement;
    publication.lien = file.files && file.files[0] ?  file.files[0] : '';
    publication.oldfilename = publication.oldfilename.split('/')[(publication.oldfilename.split('/').length) - 1];
    publication.datedebut = this.datePipe.transform(publication.datedebut, 'yyyy-MM-dd');
    publication.datefin = this.datePipe.transform(publication.datefin, 'yyyy-MM-dd');
    switch (publication.typeAction){
      case 'add':
        this.crudActions(publication, publication.typeAction);
        break;
      case 'update':
        this.crudActions(publication, publication.typeAction);
        break;
      default:
        break;
    }
  }

  crudActions = (publication: PublicationModel, type: string) => {
    publication.statutPublication = this.statutPublicationChecked;
    publication.url === undefined ? publication.url = '' :'';
    this.svcePublication.addPublication(publication).subscribe(
      (res) => {
        switch (type) {
          case 'add':
            if (res !== null && !res[`status`]) {
              this.toastr.success('Opération effectuée avec succès', 'Message', {
                timeOut: 3000,
              });
              this.dialogRef.close(true);
            }
            else{
              this.toastr.warning(res[`message`], 'Message', {
                timeOut: 3000,
              });
            }
            break;
          case 'update':
            if (res !== null && !res[`status`]) {
              this.toastr.success('Opération effectuée avec succès', 'Message', {
                timeOut: 3000,
              });
              this.dialogRef.close(true);
            }
            else{
              this.toastr.warning(res[`message`], 'Message', {
                timeOut: 3000,
              });
            }
            break;
        }
      },
      (error) => {
        console.log(error);
        this.toastr.error('Oups! erreur pendant l\'insertion. veuillez réessayer svp.', 'Message', {
          timeOut: 3000,
        });
      }
    );

  }
}
