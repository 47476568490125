'use strict';
/* export const API_REST_URL_DEV = 'http://localhost:80/SyabeProjects/API_REST_RSTI/controlers/';
export const API_REST_URL_FILE = 'http://localhost:80/SyabeProjects/API_REST_RSTI/files/';*/
export const API_REST_URL_DEV = 'https://rsti.syabe-tech.com/API_REST_RSTI/controlers/';
export const API_REST_URL_FILE = 'https://rsti.syabe-tech.com/API_REST_RSTI/files/';
export const INSERT_ACTION = 'Insert';
export const SELECTALL_ACTION = 'SelectAll';
export const SELECTALLBY_ACTION = 'SelectAllBy';
export const SELECT_ACTION = 'Select';
export const LOAD_ACTION = 'Load';
export const LOADALL_ACTION = 'LoadAll';
export const SELECTOBY_ACTION = 'SelectBy';
export const SELECTBY_ACTION = 'SelectById';
export const UPDATEBY_ACTION = 'UpdateById';
export const DELETEBY_ACTION = 'DeleteById';
export const SEARCH_ACTION = 'Rechercher';
export const FIND_ACTION = 'Retrouver';
export const FILTRE_ACTION = 'Filtre';
export const CONNECT_ACTION = 'Connect';
export const DISCONNECT_ACTION = 'Disconnect';
export const LIST_ACTION = 'List';
export const LISTBY_ACTION = 'ListBy';
export const LISTALL_ACTION = 'ListAll';
export const LISTALLBY_ACTION = 'ListAllBy';
export const SELECTALLDATA_ACTION = 'SelectAllData';
export const SELECTALLDATABY_ACTION = 'SelectAllDataBy';
// tslint:disable-next-line:max-line-length
export const USER_CONNECTED_INFO: {} = typeof(localStorage.getItem('RSTI_USER_CONNECTED_INFO')) !== 'undefined' ? JSON.parse(localStorage.getItem('RSTI_USER_CONNECTED_INFO')) : {};
export const USER_CONNECTED_ID = typeof(localStorage.getItem('RSTI_USER_CONNECTED_ID')) !== 'undefined' ? localStorage.getItem('RSTI_USER_CONNECTED_ID') : '';
