<div class="card">
  <div class="card-header">
    <h4 class="card-title">Catégorie de Métier</h4>
  </div>
  <hr>
  <div class="card-body">
    <form id="addCate" #addCate="ngForm" action="post" class="form-material m-t-20">
      <div class="row">
        <div class="col-md-12">
          <label>Code</label>
          <div class="form-group">
            <input class="form-control form-control-line"  name="code" required #code [(ngModel)]="this.data['data'].code"  autocomplete="off" type="text" placeholder="">
          </div>
        </div>
        <div class="col-md-12">
          <label>Libéllé</label>
          <div class="form-group">
            <input class="form-control form-control-line"  name="libelle" required #libelle  [(ngModel)]="this.data['data'].libelle" autocomplete="off" type="text" placeholder="Ex: Artisans... ">
          </div>
        </div>
        <div class="col-md-6">
          <label>Revenu Min</label>
          <div class="form-group">
            <input class="form-control form-control-line" required="required" mask="separator.2"  thousandSeparator=" " separatorLimit="10000000000" autocomplete="off" name="revenuemin"  #revenuemin   [(ngModel)]="this.data['data'].revenuemin"  type="text" placeholder="Ex: 45 000">
          </div>
        </div>
        <div class="col-md-6">
          <label>Cotisation Min</label>
          <div class="form-group">
            <input class="form-control form-control-line" required="required" mask="separator.2"  thousandSeparator=" " separatorLimit="10000000000" autocomplete="off" name="cotisationmin"  #cotisationmin   [(ngModel)]="this.data['data'].cotisationmin"  type="text" placeholder="Ex: 5 000">
          </div>
        </div>
        <div class="col-md-6">
          <label>Taux de base</label>
          <div class="form-group">
            <input class="form-control form-control-line" required="required" mask="separator.2"  thousandSeparator=" " separatorLimit="10000000000" autocomplete="off" name="tauxbase"  #tauxbase   [(ngModel)]="this.data['data'].tauxbase"  type="text" placeholder="Ex: 5.0">
          </div>
        </div>
        <div class="col-md-6">
          <label>taux complémentaire</label>
          <div class="form-group">
            <input class="form-control form-control-line" required="required" mask="separator.2"  thousandSeparator=" " separatorLimit="10000000000" autocomplete="off" name="tauxcomplement"  #tauxcomplement   [(ngModel)]="this.data['data'].tauxcomplement"  type="text" placeholder="Ex: 3.0">
          </div>
        </div>
      </div>
    </form>
  </div>
  <!-- <hr>-->
  <div class="card-footer" style="text-align: right">
    <button class="btn btn-action-orange" style="margin-left: 10px" (click)="close(false)">
      <span class="btn-label">
        <i class="nc-icon nc-simple-remove"></i>
      </span>
      Annuler
    </button>
    <button class="btn btn-action-green margin-left-10"  [disabled]="!addCate.form.valid" (click)="insertCategorieM()">
      <span class="btn-label">
        <i class="nc-icon nc-check-2"></i>
      </span>
      {{this.data['data'].typeAction === 'add' ? 'Enregistrer': 'Modifier'}}
    </button>
  </div>
</div>
