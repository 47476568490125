<div class="card">
  <div class="card-header">
    <h4 class="card-title">Utilisateur</h4>
  </div>
  <hr>
  <div class="card-body text-center">
    <form id="addboutique" #addProduit="ngForm" action="post" class="form-material m-t-20">
      <div class="row">
        <div class="col-md-3 float-left">
          <div class="form-group">
            <input type="file" [(ngModel)]="this.data['data'].photouser" name="image" class="form-control form-control-line dropify"  #image>
          </div>
        </div>
        <div class="col-md-9">
          <!--<div class="form-group">
            <input class="form-control form-control-line"  name="code" readonly required #numcnam  [(ngModel)]="this.data['data'].numcnam"  type="text" placeholder="N° CNAM">
          </div>-->
          <div class="row">
            <div class="col-md-5">
              <div class="form-group">
                <input class="form-control form-control-line" style="text-transform: capitalize" disabled #nom name="nom" required [(ngModel)]="this.data['data'].nom"  type="text" placeholder="Nom">
              </div>
            </div>
            <div class="col-md-7">
              <div class="form-group">
                <input class="form-control form-control-line" style="text-transform: capitalize"  disabled autocomplete="off" #nom name="prenoms" required [(ngModel)]="this.data['data'].prenoms"  type="text" placeholder="Prénoms">
              </div>
            </div>
          </div>
          <div class="form-group">
            <input class="form-control form-control-line" style="text-transform: lowercase"  disabled autocomplete="off" #email name="email" required [(ngModel)]="this.data['data'].email"  type="text" placeholder="email">
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <input class="form-control form-control-line" style="text-transform: lowercase"  disabled autocomplete="off" #contact name="contact" required [dropSpecialCharacters]="true" [showMaskTyped]="true" mask=" 00 00 00 00 00" prefix="(225)"  [(ngModel)]="this.data['data'].tel"  type="text" placeholder="Contact...">
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <select class="form-control form-control-line"  name="typeutilisateurid" disabled [(ngModel)]="this.data['data'].typeutilisateurid" required >
              <option value="" disabled selected hidden>--Choisir le rôle--</option>
              <option *ngFor="let item of this.listeTypeUser" [value]="item.typeutilisateurid"> {{item.libelle}}</option>
            </select>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <input class="form-control form-control-line"  #password name="password" autocomplete="off" required [(ngModel)]="this.data['data'].password"  type="password" placeholder="Mot de passe">
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <input class="form-control form-control-line"  #cpassword name="cpassword" autocomplete="off"  required [(ngModel)]="this.data['data'].cpassword"  type="password" placeholder="confirmer le mot de passe">
          </div>
        </div>
      </div>
    </form>
  </div>
  <!-- <hr>-->
  <div class="card-footer" style="text-align: right">
    <button class="btn btn-action-orange" style="margin-left: 10px" (click)="close(false)">
      <span class="btn-label">
        <i class="nc-icon nc-simple-remove"></i>
      </span>
      Annuler
    </button>
    <button class="btn btn-action-green margin-left-10" (click)="insertAccess()">
      <span class="btn-label">
        <i class="nc-icon nc-check-2"></i>
      </span>
      {{this.data['data'].typeAction === 'add' ? 'Enregistrer': 'Modifier'}}
    </button>
  </div>
</div>
